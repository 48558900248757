.info-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    margin: 20px;
  }
  .help-cards-image img{
    width: 60%;
    text-align: center;
  }
  .help-card{
    margin: 20px 0;
  }
  .info-card-icon {
    margin-right: 15px;
  }
  
  .question-icon {
    background-color: #660032;
    color: #fff;
    font-size: 24px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .info-card-content h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
  }
  
  .info-card-content p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .info-card-content a {
    color: #660032;
    text-decoration: none;
  }
  
  .info-card-content a:hover {
    text-decoration: underline;
  }
  