/* Tile Dashboard Container */
.sqano-Tile-dashboard-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* padding: 20px; */
}

@media (min-width: 992px) and (max-width: 1200px) {
  .sqano-Tile-dashboard-container {
    flex-direction: column;
    align-items: center;
  }
}

.sqano-Tile-dashboard-card {
  background-color: #ffffff;
  color: #660032;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.sqano-Tile-dashboard-card-icon {
  font-size: 2em;
  color: #660032;
  position: absolute;
  top: 20px;
  right: 20px;
}

.sqano-Tile-dashboard-card-title {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #660032;
}

.sqano-Tile-dashboard-card-value {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #660032;
}

.sqano-Tile-dashboard-card-detail {
  font-size: 0.9em;
}

.dashboard-card-content {
  padding-right: 60px; /* Ensure there is space for the icon */
  width: 100%;
}

/* Media queries for different screen sizes */
@media (max-width: 320px) {
  .sqano-Tile-dashboard-card {
    width: 100%;
    margin: 5px;
  }
  .sqano-Tile-dashboard-card-title,
  .sqano-Tile-dashboard-card-value,
  .sqano-Tile-dashboard-card-detail,
  .sqano-Tile-dashboard-card-icon {
    font-size: 9px;
  }
  .sqano-Tile-dashboard-card-icon {
    font-size: 1em;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .sqano-Tile-dashboard-card {
    width: 220px;
  }
  .sqano-Tile-dashboard-card-title,
  .sqano-Tile-dashboard-card-value,
  .sqano-Tile-dashboard-card-detail,
  .sqano-Tile-dashboard-card-icon {
    font-size: 10px;
  }
  .sqano-Tile-dashboard-card-icon {
    font-size: 1.2em;
  }
}

@media (min-width: 481px) and (max-width: 520px) {
  .sqano-Tile-dashboard-card {
    width: 240px;
  }
  .sqano-Tile-dashboard-card-title,
  .sqano-Tile-dashboard-card-value,
  .sqano-Tile-dashboard-card-detail,
  .sqano-Tile-dashboard-card-icon {
    font-size: 11px;
  }
  .sqano-Tile-dashboard-card-icon {
    font-size: 1.4em;
  }
}

@media (min-width: 521px) and (max-width: 560px) {
  .sqano-Tile-dashboard-card {
    width: 260px;
  }
  .sqano-Tile-dashboard-card-title,
  .sqano-Tile-dashboard-card-value,
  .sqano-Tile-dashboard-card-detail,
  .sqano-Tile-dashboard-card-icon {
    font-size: 12px;
  }
  .sqano-Tile-dashboard-card-icon {
    font-size: 1.6em;
  }
}

@media (min-width: 561px) and (max-width: 768px) {
  .sqano-Tile-dashboard-card {
    width: 280px;
  }
  .sqano-Tile-dashboard-card-title,
  .sqano-Tile-dashboard-card-value,
  .sqano-Tile-dashboard-card-detail,
  .sqano-Tile-dashboard-card-icon {
    font-size: 13px;
  }
  .sqano-Tile-dashboard-card-icon {
    font-size: 1.8em;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .sqano-Tile-dashboard-card {
    width: 300px;
  }
  .sqano-Tile-dashboard-card-title,
  .sqano-Tile-dashboard-card-value,
  .sqano-Tile-dashboard-card-detail,
  .sqano-Tile-dashboard-card-icon {
    font-size: 14px;
  }
  .sqano-Tile-dashboard-card-icon {
    font-size: 2em;
  }
}

@media (min-width: 993px) and (max-width: 1200px) {
  .sqano-Tile-dashboard-card {
    width: 320px;
  }
  .sqano-Tile-dashboard-card-title,
  .sqano-Tile-dashboard-card-value,
  .sqano-Tile-dashboard-card-detail,
  .sqano-Tile-dashboard-card-icon {
    font-size: 15px;
  }
  .sqano-Tile-dashboard-card-icon {
    font-size: 2.2em;
  }
}

@media (min-width: 1201px) {
  .sqano-Tile-dashboard-card {
    width: 340px;
  }
  .sqano-Tile-dashboard-card-title,
  .sqano-Tile-dashboard-card-value,
  .sqano-Tile-dashboard-card-detail,
  .sqano-Tile-dashboard-card-icon {
    font-size: 16px;
  }
  .sqano-Tile-dashboard-card-icon {
    font-size: 2.4em;
  }
}
