.register-page {
    overflow: hidden;
}

.register-background {
  background-image: url('./../../assets/images/t-1.jpg');
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
}

.register-box{
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 20px; */
  padding-bottom: 40px;
  margin: auto;
}
.register-container {
  background-color: #ffffff;
  border-radius: 28px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  padding: 20px;
  max-width: 800px; /* Maximum width of the container */
  width: 90%; /* Width that allows it to shrink on smaller screens */
  overflow: hidden; /* Prevents content from overflowing the container */
}

.register-container .register-card {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16); /* Add a subtle shadow */
  border-radius: 24px; /* Optional: Add rounded corners */
}

.register-form-group {
  margin-bottom: 10px;
  position: relative;
  flex-direction: column; /* Stack label and input vertically */
}

.register-image{
  width: 300px;
}

.login-p{
  padding: 5px;
}

.register-login{  
  color: #660032;
  }

  .register-input-group {
    display: flex;
    align-items: center;
  }

.register-input{
  width: 100%;
  border: 0;
  box-sizing: border-box;
  border-radius: 9999px;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
  color: #6c6c6c;
  font-size: 14px;
  font-family: "Roboto";
  /* line-height: 44px; */
  outline: none;  
  /* border-bottom: 1px solid #ab3a71; */
  padding-right: '2.5rem';
  position: relative; /* Ensure the input field's relative position */
}

.register-password-group {
  display: flex;
  flex-direction: column;
  /* border-bottom: 1px solid #ab3a71; */
}

.register-input-icon-group {
  position: relative;
  display: flex;
  align-items: center;
}

.register-password-input {
  flex-grow: 1;
  width: 100%;
  border: 0;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
  color: #6c6c6c;
  font-size: 14px;
  font-family: "Roboto";
  /* line-height: 44px; */
  outline: none;  
  padding-right: 40px;
  padding: 0.75rem 1rem; /* Adjust padding for better spacing */
   /* Ensure the input field's relative position */
  padding: 0.75rem 1rem; /* Adjust padding for better spacing */
}

.email-icon {
  background-color: transparent;
  color: #660032; /* Change color as needed */
  display: flex;
  align-items: center;
  border: 'none';
  position: 'absolute';
  right: '10px';
  top: '50%';
  transform: 'translateY(-50%)';
  cursor: 'pointer';
  z-index: '10'; 
  pointer-events: 'none';
}

.register-eye-icon{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px; /* Adjust icon size as needed */
  color: #660032;
}

.register-password-requirements {
  color: #f56c6c;
  font-size: 12px;
  margin-top: 5px;
  font-family: 'Roboto';
}



.toast-progress-bar {
  background-color: #660032 !important; /* Change the progress bar color */
  color: #660032;
}

.custom-toast-icon {
  background-color: #660032; /* Set the background color of the custom icon */
  border-radius: 50%; /* Make the background circular */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* width: 24px; 
  height: 24px; */
  color: white; /* Set icon color to white for visibility */
}


.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-color: var(--bs-form-invalid-border-color);
  padding-right: calc(1.5em + 0.75rem);
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.is-invalid ~ .invalid-feedback {
  display: block;
}

.form-check-input:checked {
  background-color: #660032 !important;
  border-color: #660032 !important;
}

.register-btn-custom {
  background-color: #660032 !important;
  border-color: #660032 !important;
  border-radius: 20px !important;
  margin-top: 12px;
  font-size: 12px;
  font-family: 'Roboto';
}




