.parseit_section_container {
    display: flex;
    align-items: stretch;
}
.parseit_section {
    background-color: #fff;
    color: #660032;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Ensure all children have the same height */
}

/* Section styles */
.parseit_one {
    flex: 1;
    height: 100%;
    overflow: auto;
    min-height: 500px;
}

.parseit_one_title {
    background-color: #660032;
    color: #fff;
    padding: 5px;
    margin: 15px;
    text-align: center;
    position: relative;
}

.parseit_one_title::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #660032;
}


.parseit_dropzone {
    border-radius: 10px;
    margin: 25px;
    padding: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
    border: 2px dashed #660032 !important;
    & p{
        color: #660032;
    }
    h5{
        font-size: 14px;
    }
}

.parseit_back_arrow {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    cursor: pointer;
}

.parseit_one_info {
    margin: 20px;

}

.parseit_table {
    height: 100%;
}

.parseit_two {
    padding: 15px;
    height: 100%;
    min-height: 500px;
    /* min-height: 60vh; */
}

.parseit_two_topbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    overflow-x: auto;
}

.parstit_buttons_container {
    display: flex;
    margin-bottom: 5px;
    font-size: 12px;
    font-style: 'Roboto';
}

.parseit_button{
    font-size: 12px;
    color: #660032 !important;
    padding: 5px 10px !important;
    cursor: pointer;
    border: 1px solid #660032 !important;
    margin-right: 5px !important;
    height: 25px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: 'Roboto';
}

.parseit_button.active {
    background-color: #660032 !important;
    color: #fff !important;
}

.parseit_button.active:hover {
    background-color: #660032 !important;
}

.parseit_button.inactive {
    opacity: 0.5;
    cursor: pointer !important;
}

.parseit_icon-mail {
    border: 1px solid #660032;
    padding: 0px 2px;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 5px;
    height: 25px;
}

.parseit_icon-mail.inactive {
    opacity: 0.5;
    cursor: default;
}

.parseit_icon {
    margin-left: 5px !important;
    color: #660032 !important;
    cursor: pointer;
    margin-right: 5px;
    padding: 0px !important;
    margin: 0px !important;
    font-size: "12px";
    border: 1px solid #660032;
    border-radius: 5px;
    height: "100%"
}

.parseit_icon.inactive {
    opacity: 0.5;
    cursor: default;
}

.parseit_img_box {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #660032;
    padding: 2px;
    border-radius: 5px;
    cursor: pointer;
}

.parseit_img_box.inactive {
    opacity: 0.5;
    cursor: default;
}

.parseit_img {
    width: 20px;
    height: 20px;
}



.parseit_three {
    /* height: auto; */
    height: 100%;
    width: 100%;
    min-height: 500px;
    color: #660032;
}

.parseit_profile_view {
    /* height: auto; */
    padding: 10px;
    /* margin: 10px; */
    /* overflow: scroll; */
    scrollbar-width: none;
    margin-left: 15px;

}

.parseit_profile_row {
    display: flex;
    flex-direction: row;
    color: #660032;
}

.parseit_profile_box {
    background-color: lightgray;
    padding: 10px;
    border-radius: 5px;
}
.parseit_profile_title{
    border-bottom: #660032 solid 2px;
    padding-bottom: 5px;
    width: fit-content;
}
.parseit_profile_label {
    font-weight: bolder;
    flex: 1;
    color: #660032;
}
.parseit_profile_value{
    color: #660032;
}

.parseit_profile_chip{
    color: #660032;
    border-color: #660032;
}