/* Styles for the tabs container */
.tabs {
  display: flex;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-width: fit-content;
}

/* Styles for the tab buttons */
.tabs-buttons {
  position: relative;
  border: none !important; /* Using !important to override MUI styles */
  padding: 5px 15px;
  border-radius: 4px;
  color: #660032;
  background-color: white;
}

.tabs-buttons.active {
  background-color: #660032 !important;
  color: #fff !important;
}
.tab-icon {
  margin-right: 8px;
  color: #660032;
}
.tab-icon.active{
  color: #fff;
}
/* Triangle below active tab */
.tabs-buttons.active::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #660032;
}

/* Override MUI button padding */
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  line-height: 1 !important;
  padding: 5px 8px !important; /* Ensure padding is applied */
  border: none !important;
  border-color: #fff !important;
}



.filter-button-container {
  display: flex;
  align-items: center;
  border-radius: 30px;
  padding: 10px;
  background-color: #ffffff;
  height: 30px;
  border-radius: "10px"; // Rounded corners for the entire TextField
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); // Custom box shadow
}

.filter-toggle-button {
  gap: 5px;
  display: flex;
  align-items: center;
  border: none;
  background: transparent;
  color: #8b0046;
  cursor: pointer;
  font-size: 10px; /* Reduced font size */
}

.filter-toggle-button:hover {
  background-color: rgba(139, 0, 70, 0.1);
  border-radius: 20px;
}


.filter-button-options {
  display: flex;
  gap: 10px;
  margin-left: 10px;
  padding: 5px;
}

.filter-button-options .Filter-option {
  border-color: transparent !important;
  border-radius: 20px;
  color: #8b0046;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-items: center;
  font-size: 12px !important; /* Reduced font size */
  height: 25px;
}

.filter-button-options .Filter-option:hover {
  background-color: rgba(139, 0, 70, 0.1);
}
/* customStyles.css */
/* ScanItHistory.css */
.MuiTableFooter-root {
  background-color: #660032 !important;
  color: #fff !important;
}
.MuiTablePagination-selectLabel{
  color: white;
}
// .MuiSvgIcon-root-MuiSelect-icon{
//   color: #fff;
// }
.MuiTablePagination-displayedRows{
  color: #fff;
}
.nativeInput{
  color: #fff;
}
// img, svg{
//   color: #fff;
// }
.MuiSelect-select-MuiInputBase-input.MuiSelect-select{
  color: red;
}
.MuiTablePagination-select{
  color: white !important;
}
.MuiSvgIcon-root-MuiSelect-ico{
  color: #fff !important;
}
.MuiSelect-icon{
  color: #fff !important;
}
.custom-scrollbar {
  overflow-y: auto;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 4px !important;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f4f4f4 !important; /* Optional */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #660032 !important;
  border-radius: 4px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #4d0026 !important; /* Darker shade on hover */
}
