.drawer {
  position: fixed;
  top: 0;
  right: -100%; /* Hidden by default */
  width: 300px;
  height: 100%;
  background-color: #5a002d; /* Background color from the image */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  z-index: 1001;
  overflow-y: auto; /* Added for scrolling if content is large */
  padding: 20px; /* Add padding for content */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.drawer.active {
  right: 0; /* Show when active */
}

.drawer__close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #fff; /* White color for close icon */
}

.drawer__logo {
  display: block;
  margin-bottom: 20px;
}

.drawer__logo img {
 /* Adjusted to fit better */
  /* height: auto; */
  height: 70px;
}

.drawer__text {
  font-size: 14px !important;
  margin-bottom: 20px;
  color: #fff; /* White color for text */
}

.drawer__info {
  margin-bottom: 20px;
  width: 100%;
}

.drawer__info-item {
  margin-bottom: 15px; /* Increased margin for better spacing */
}

.drawer__info-title {
  font-weight: bold;
  display: block;
  color: #ffce00; /* Yellow color for titles */
}

.drawer__info-link {
  color: #ffce00; /* Yellow color for links */
  text-decoration: none;
}

.drawer__info-link:hover {
  text-decoration: underline;
}

.drawer__social {
  display: flex;
  gap: 15px; /* Increased gap for better spacing */
  justify-content: center;
  margin-bottom: 20px;
}

.drawer__social-item {
  list-style: none;
}

.drawer__social-link {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  color: #5a002d;
  transition:
    background-color 0.3s,
    color 0.3s;
}

.drawer__social-link:hover {
  background-color: #ffce00;
  color: #5a002d;
}

.drawer__button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: #ffce00; /* Yellow background for button */
  color: #5a002d; /* Dark text color for button */
  text-decoration: none;
  border-radius: 5px;
  width: 80%; /* Adjusted width */
  margin-top: 20px; /* Added margin for spacing */
}

.drawer__button:hover {
  background-color: #fff;
  color: #5a002d;
}

/* Basic mobile styling (320px and below) */
@media (max-width: 320px) {
  .drawer {
    width: 220px;
    padding: 10px;
  }

  .drawer__text {
    font-size: 14px;
  }

  .drawer__button {
    width: 100%;
  }
}

/* Small mobile devices (321px to 480px) */
@media (min-width: 321px) and (max-width: 480px) {
  .drawer {
    width: 240px;
    padding: 10px;
  }

  .drawer__text {
    font-size: 14px;
  }

  .drawer__button {
    width: 95%;
  }
}

/* Medium mobile devices (481px to 520px) */
@media (min-width: 481px) and (max-width: 520px) {
  .drawer {
    width: 260px;
    padding: 15px;
  }

  .drawer__text {
    font-size: 14px;
  }

  .drawer__button {
    width: 90%;
  }
}

/* Large mobile devices (521px to 560px) */
@media (min-width: 521px) and (max-width: 560px) {
  .drawer {
    width: 260px;
    padding: 15px;
  }

  .drawer__text {
    font-size: 14px;
  }

  .drawer__button {
    width: 90%;
  }
}

/* Small tablets (561px to 768px) */
@media (min-width: 561px) and (max-width: 768px) {
  .drawer {
    width: 280px;
    padding: 18px;
  }

  .drawer__text {
    font-size: 15px;
  }

  .drawer__button {
    width: 85%;
  }
}

/* Tablets and small desktops (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  .drawer {
    width: 280px;
    padding: 18px;
  }

  .drawer__text {
    font-size: 15px;
  }

  .drawer__button {
    width: 85%;
  }
}

/* Desktops (1025px to 1279px) */
@media (min-width: 1025px) and (max-width: 1279px) {
  .drawer {
    width: 300px;
    padding: 20px;
  }

  .drawer__text {
    font-size: 16px;
  }

  .drawer__button {
    width: 80%;
  }
}

/* Large screens and desktops (1280px and above) */
@media (min-width: 1280px) {
  .drawer {
    width: 320px;
    padding: 25px;
  }

  .drawer__text {
    font-size: 18px;
  }

  .drawer__button {
    width: 75%;
  }
}
