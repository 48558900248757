.history-parse-it-table-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .parse-it-history-textfeild{
   padding-top: 0 !important;
   margin-top: 0px !important;
  }

  .parse-it-history-label{
    margin-bottom: 0px !important;
  }
  .parse-it-history-textfeild .MuiOutlinedInput-input{
    padding: 5px !important;
    font-size: 12px !important;
    font-family: 'Roboto';
  }
  .filter-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
  }

  .filter-modal-container{
    background-color: white;
  }
  
  .filter-modal-title{
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: 16px;
    color: white;
    padding: 10px;
    background-color: #660032;
  }

  .filter-modal-content {
    padding: 20px;
  }

  .filter-modal-button{
    background-color: #660032;
    color: white;
    padding: 5px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
  }

  .filter-modal-input{
    width: 100%;
    padding: 5px;
  }

  .filter-modal-input-date{
    width: 75%;
    padding: 5px;
  }

  .filter-modal-select{
    width: 100%;
    padding: 7.5px;
  }