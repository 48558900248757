

  /* #sidebar-menu {
    transition: width 0.3s;
  }

  #sidebar-menu.collapsed {
    width: 80px;
  }
  
  #sidebar-menu .menu-text {
    display: inline-block;
    transition: opacity 0.3s;
  }
  
  #sidebar-menu.collapsed .menu-text {
    opacity: 0;
    visibility: hidden;
  }
  
  #sidebar-menu.collapsed .waves-effect:hover .menu-text {
    opacity: 1;
    visibility: visible;
    position: absolute;
    left: 80px;
    background: rgba(0, 0, 0, 0.7);
    padding: 5px 10px;
    border-radius: 4px;
    white-space: nowrap;
    z-index: 1000;
  }
  
  
   */
  /* #sidebar-menu ul li:active {
    background-color: rgb(217, 217, 101) !important;
    color: green !important;
  }
   */
   .metismenu img {
    width: 22px;
    margin-right: 10px;
    margin-left: 5px;
  }
  .custom-img{
    margin-left: 5px;
    width: 12px !important;
  }
   /* Remove background color for active li */
li.active {
    background-color: transparent !important;
}

.arrow-icon {
  transition: transform 0.3s ease;
  margin-left: 80px !important;
}

.rotate-down {
  transform: rotate(90deg); /* Adjust this based on your icon */
}

/* Remove background color for active a */
/* CSS for active links */

/* li a.active,
li.mm-active a,
li.mm-show a {
    background-color: black !important;
} */
/* #sidebar-menu ul > li > a.mm-active {
    background-color: transparent;
} */
/* CSS for active sub-dropdown links */


.sub-dropdown li a.active {
    background-color: green !important;
}
/* .sub-dropdown .active-sub-link {
    background-color: #f0f0f0; /* Adjust as necessary 
} */

/* Remove background color for active span */
/* li span.active,
li.mm-active span,
li.mm-show span {
    background-color: transparent !important;
} */

/* Remove background color for active sublist */
/* #sidebar-menu ul li:active {
    background-color: transparent !important;
} */

/* ul.sub-dropdown li.active {
    background-color: transparent !important;
} */

ul.sub-dropdown li.mm-active {
    background-color: transparent !important;
}

ul.sub-dropdown li.mm-show {
    background-color: transparent !important;
}
.header-icon{
  color: white !important;
} 