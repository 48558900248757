.cv-parser-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #660032;
  color: white;
  padding: 20px;
  border-radius: 20px;
  margin: 0 auto;

}

.title {
  margin-bottom: 20px;
  font-size: 24px;
}

.dropzone {
  border: 2px dashed #ff9900;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  cursor: pointer;
  min-height: 170px !important;

}

.fields-extracted {
  background-color: white;
  color: black;
  padding: 10px;
  border-radius: 10px;
  width: 100%;
}

.fields-extracted h5 {
  font-size: 12px;
  margin-bottom: 10px;
}

.fields-extracted ul {
  list-style-type: none;
  padding: 0;
}

.fields-extracted li {
  margin-bottom: 5px;
  font-size: 10px;
}
.dropzone h4{
  color: #660032;
  margin-bottom: 20px;
}
.dropzone p{
  color: #000;
  text-align: center;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
}
.scroll-container {
  max-height: 500px; /* Adjust this height as needed */
  overflow-y: auto;
  width: 100%;
}
.cv-scanning-form{
  background-color: #660032;
  border-radius: 20px;
}
.product-heading{
  color: #ffffff;
  text-align: center;
}
.search-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  outline: none;
}

.search-button {
  padding: 10px 20px;
  border: none;
  background-color: #71002a;
  color: white;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

.search-button:hover {
  background-color: #5b0023;
}
// .tabs {
//   display: flex;
//   width: 100%;
//   margin-bottom: 20px;
// }

// .tab {
//   flex: 1 1;
//   padding: 4px 1;
//   border: none;
//   cursor: pointer;
//   border-radius: 15px;
//   font-size: 11px;
// }

// .tab.active {
//   background-color: #28a745;
//   color: white;
// }
.product-tabs {
  display: flex;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  max-width: fit-content;
  margin-bottom: 20px;
}

/* Styles for the tab buttons */
.product-tabs-buttons {
  position: relative;
  border: none !important; /* Using !important to override MUI styles */
  padding: 5px 15px;
  border-radius: 4px;
  color: #660032;
  background-color: white;
  font-size: 12px;
  font-family: 'Roboto';
}

.product-tabs-buttons.active {
  background-color: #660032 !important;
  color: #fff !important;
}

/* Triangle below active tab */
.product-tabs-buttons.active::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #660032;
}

/* Override MUI button padding */
.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  line-height: 1 !important;
  padding: 5px 8px !important; /* Ensure padding is applied */
  border: none !important;
  border-color: #fff !important;
}

.product-tab-icon {
  color: #660032;
  margin-right: 8px;
}

.product-tab-icon.active {
  color: #fff;
}

.tab-divider {
  border-color: #660032 !important;
}

.notification-badge {
  position: absolute;
  top: 7px;
  right: -10px;
  background-color: green;
  color: white;
  padding: 5px 10px;
  border-radius: 50%;
  font-size: 10px;
  animation: moveLeftRight 2s linear infinite;
}


.green-bg {
  background-color: #55ce63;
  font-size: 8px;
  position: absolute;
  top: -16px; 
  left: 314px; 
  color: white;
  border-radius: 50%;
  padding: 7px 4px;
  height: 20px;
  width: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: moveLeftRight 2s linear infinite;
}
.yellow-bg {
  background-color: #55ce63;
  position: absolute;
  font-size: 8px;
  top: -16px;
  left: 201px;
  color: white;
  border-radius: 50%;
  padding: 7px 4px;
  height: 20px;
  width: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: moveLeftRight 2s linear infinite;
}
@keyframes moveLeftRight {
  0%, 100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-5px);
  }
}



.product-wrapper .form-container {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 1px;
}

.product-wrapper .form-container::-webkit-scrollbar {
  width: 8px;
}

.product-wrapper .form-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.product-wrapper .form-container::-webkit-scrollbar-thumb {
  background-color: #9b215c; /* Your desired scrollbar color */
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.product-wrapper .form-container::-webkit-scrollbar-thumb:hover {
  background: #660032; /* Darker shade on hover */
}
.css-19midj6 {
 padding: 5cm;
}


.tab-container {
  position: relative;
  padding: 0;
  margin: 0 5px !important;
  border-radius: 5px !important;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  box-shadow: none;
  display: block;
  font-size: 10px !important;
  height: 5px; 
  width: 103px; 
  padding: 1px;
  border: 1px solid #660032 !important;
  color: #660032;

  &.active-tab {
    background-color: #660032;
    border: none;
    color: #ffffff !important;
    box-shadow: 0 0 20px #eee;
    border-radius: 5px;

    &:after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #000000;
      z-index: 99;
    }
  }
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  min-height: 30px !important;
}