//
// _helper.scss
//

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-15 {
  font-size: 15px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-17 {
  font-size: 17px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.font-size-20 {
  font-size: 20px !important;
}

.font-size-22 {
  font-size: 22px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

// Font weight help class

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

// Social

.social-list-item {
  height: 2rem;
  width: 2rem;
  line-height: calc(2rem - 2px);
  display: block;
  border: 1px solid var(--#{$prefix}border-color);
  border-radius: 50%;
  color: $gray-500;
  text-align: center;
}

.social-links {
  li {
    a {
      -webkit-border-radius: 50%;
      border-radius: 50%;
      display: inline-block;
      height: 30px;
      line-height: 30px;
      width: 30px;
    }
  }
}


.w-xs {
  min-width: 80px;
}
.w-sm {
  min-width: 95px;
}
.w-md {
  min-width: 110px;
}
.w-lg {
  min-width: 140px;
}

.w-xl {
  min-width: 160px;
}


// media

// alert

.alert-dismissible {
  .btn-close{
    font-size: 10px;
    padding: $alert-padding-y * 1.4 $alert-padding-x;
    // background: transparent escape-svg($btn-close-bg-dark) center / $btn-close-width auto no-repeat;
  }
}