/* Miniwidget Card CSS */
.miniwidget-card {
  background-color: #fff;
  color: #660032;
  height: 100%;
  min-height: 150px; /* Default minimum height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
}

.miniwidget-icon {
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #660032;
}

.miniwidget-title {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #660032;
}

.miniwidget-total {
  font-size: 20px;
  color: #660032;
  margin-bottom: 1rem;
}

.miniwidget-col {
  margin-bottom: 1rem;
}

/* Media queries for different laptop screen sizes */
@media (max-width: 320px) {
  .miniwidget-card {
    min-height: 110px; /* Adjust min-height for the smallest screens */
  }
  .miniwidget-title,
  .miniwidget-total,
  .miniwidget-icon,
  .miniwidget-col {
    font-size: 10px;
  }
  .miniwidget-total {
    font-size: 12px;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .miniwidget-card {
    min-height: 120px; /* Adjust min-height for screens between 321px and 480px */
  }
  .miniwidget-title,
  .miniwidget-total,
  .miniwidget-icon,
  .miniwidget-col {
    font-size: 11px;
  }
  .miniwidget-total {
    font-size: 14px;
  }
}

@media (min-width: 481px) and (max-width: 520px) {
  .miniwidget-card {
    min-height: 130px; /* Adjust min-height for screens between 481px and 520px */
  }
  .miniwidget-title,
  .miniwidget-total,
  .miniwidget-icon,
  .miniwidget-col {
    font-size: 12px;
  }
  .miniwidget-total {
    font-size: 16px;
  }
}

@media (min-width: 521px) and (max-width: 560px) {
  .miniwidget-card {
    min-height: 140px; /* Adjust min-height for screens between 521px and 560px */
  }
  .miniwidget-title,
  .miniwidget-total,
  .miniwidget-icon,
  .miniwidget-col {
    font-size: 13px;
  }
  .miniwidget-total {
    font-size: 18px;
  }
}

@media (min-width: 561px) and (max-width: 768px) {
  .miniwidget-card {
    min-height: 120px; /* Adjust min-height for screens between 561px and 768px */
  }
  .miniwidget-title,
  .miniwidget-total,
  .miniwidget-icon,
  .miniwidget-col {
    font-size: 11px;
  }
  .miniwidget-total {
    font-size: 14px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .miniwidget-card {
    min-height: 130px; /* Adjust min-height for screens between 769px and 1024px */
  }
  .miniwidget-title,
  .miniwidget-total,
  .miniwidget-icon,
  .miniwidget-col {
    font-size: 12px;
  }
  .miniwidget-total {
    font-size: 16px;
  }
}

@media (min-width: 1025px) and (max-width: 1279px) {
  .miniwidget-card {
    min-height: 140px; /* Adjust min-height for screens between 1025px and 1279px */
  }
  .miniwidget-title,
  .miniwidget-total,
  .miniwidget-icon,
  .miniwidget-col {
    font-size: 13px;
  }
  .miniwidget-total {
    font-size: 18px;
  }
}

@media (min-width: 1280px) {
  .miniwidget-card {
    min-height: 150px; /* Default minimum height for large screens (1280px and above) */
  }
  .miniwidget-title,
  .miniwidget-total,
  .miniwidget-icon,
  .miniwidget-col {
    font-size: 14px;
  }
  .miniwidget-total {
    font-size: 20px;
  }
}


/* Dashboard Graph Card CSS */
.dashboard-graph-card {
  position: relative;
  padding: 20px;
}

.dashboard-graph-title {
  text-align: left;
  font-size: 14px;
}

.dashboard-graph-value {
  font-size: 14px;
  color: #c0c0c0;
}

.dashboard-graph-text {
  font-size: 16px;
  color: #660032;
  margin-right: 15px;
}

.dashboard-graph-chart-container {
  margin-top: 10px;
  height: 300px;
}

/* Media queries for different laptop screen sizes */
@media (max-width: 320px) {
  .dashboard-graph-card {
    height: 300px;
  }
  .dashboard-graph-title,
  .dashboard-graph-value,
  .dashboard-graph-text {
    font-size: 13px;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  .dashboard-graph-card {
    height: 300px;
  }
  .dashboard-graph-title,
  .dashboard-graph-value,
  .dashboard-graph-text {
    font-size: 13px;
  }
}

@media (min-width: 481px) and (max-width: 520px) {
  .dashboard-graph-card {
    height: 400px;
  }
  .dashboard-graph-title,
  .dashboard-graph-value,
  .dashboard-graph-text {
    font-size: 12px;
  }
}

@media (min-width: 521px) and (max-width: 560px) {
  .dashboard-graph-card {
    height: 400px;
  }
  .dashboard-graph-title,
  .dashboard-graph-value,
  .dashboard-graph-text {
    font-size: 12px;
  }
}

@media (min-width: 561px) and (max-width: 768px) {
  .dashboard-graph-card {
    height: 500px;
  }
  .dashboard-graph-title,
  .dashboard-graph-value,
  .dashboard-graph-text {
    font-size: 11px;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .dashboard-graph-card {
    height: 600px;
  }
  .dashboard-graph-title,
  .dashboard-graph-value,
  .dashboard-graph-text {
    font-size: 11px;
  }
}

@media (min-width: 1025px) and (max-width: 1279px) {
  .dashboard-graph-card {
    height: 700px;
  }
  .dashboard-graph-title,
  .dashboard-graph-value,
  .dashboard-graph-text {
    font-size: 14px;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  .dashboard-graph-card {
    height: 800px;
  }
  .dashboard-graph-title,
  .dashboard-graph-value,
  .dashboard-graph-text {
    font-size: 13px;
  }
}

@media (min-width: 1440px) and (max-width: 1599px) {
  .dashboard-graph-card {
    height: 800px;
  }
  .dashboard-graph-title,
  .dashboard-graph-value,
  .dashboard-graph-text {
    font-size: 16px;
  }
}

@media (min-width: 1600px) {
  .dashboard-graph-card {
    height: 900px;
  }
  .dashboard-graph-title,
  .dashboard-graph-value,
  .dashboard-graph-text {
    font-size: 18px;
  }
}



/* Toggle Switch for admin tile box-view CSS */
.sqano-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.sqano-switch .sqano-tile-switch {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #660032;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.profiles-sources-col{
  display: flex;
}