/* Filters.css */
.filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  /* border: 1px solid #660032; */
}


.cv-access-input-label {
  color: #660032;
  /* font-size: 10px; */
  margin-bottom: 5px !important;
 }

  .filter-input {
    border: none;
    border: 1px solid #660032 !important; 
    padding: 8px; /* Adds padding inside the input field */
    width: 100%;
    box-sizing: border-box;
    font-size: 12px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 8px;
  }
  .filter-card {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 4px; */
  }
  
  .selected-value {
    color: #660032;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 4px;
    margin: 8px 0;
    border-radius: 4px;
  }


.close-icon {
  margin-left: 8px;
  cursor: pointer;
}

.filter-icon {
  margin-right: 8px; /* Adjust as needed */
  color: white; /* Replace with your desired color */
}
.cv-access-form-button{
  display: none;
}

.cv-access-chip{
  background-color: #660032 !important;
  color: #fff !important;
}
.cv-access-chip .MuiChip-deleteIcon{
  color: #fff !important;
}