.generatepdf-header {
    background-color: white;
    padding: 10px 15px;
    display: flex;
    justify-content: center;
  }
  
  .header-logo {
    height: 40px;
  }
  