// _footer.scss

.footer {
  background-color: #660032;
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} / 2);
  position: absolute;
  text-align: center;
  right: 0;
  color: #ffffff;
  left: 200px;
  height: $footer-height;
  box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.05);
  z-index: 1000;
}

@media (max-width: 992px) {
  .footer {
    left: 0;
  }
}

// Enlarge menu
.vertical-collpsed {
  .footer {
    left: $sidebar-collapsed-width;
  }
}

body[data-layout="horizontal"] {
  .footer {
    left: 0 !important;
  }
}

.white {
  color: #ffffff;
}
