/* =============
   General
============= */

html {
  position: relative;
  min-height: 100%;
  background-color: #f2f2f2;
  font-family: 'Roboto';

}

p{
  line-height: 1.7;
}

.h1,.h2,.h3,.h4,.h5,
h1,h2,h3,h4,h5 {
    // line-height: 1.2;
    font-size: 16px !important;
    font-family: 'Roboto';
    
}
h6{
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}
a {
  text-decoration: none !important;
  font-family: 'Roboto';
}

// span{
//   font-size: 12px !important;
//   font-family: 'Roboto' !important;
// }


label {
  margin-bottom: $form-label-margin-bottom;
  font-family: 'Roboto';
}

// Small


// blockquote

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid var(--#{$prefix}border-color);
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid var(--#{$prefix}border-color);
  text-align: right;
}


// container

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
  @media (min-width: 1200px){
      max-width: 1140px;
  }
}

// row

.row{
  > * {
      position: relative;
  }
}
/* Customize the scrollbar */
::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

/* The background (track) of the scrollbar */
::-webkit-scrollbar-track {
  background-color: #f4f4f4 !important; /* Optional */
}

/* The draggable scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #660032 !important;
}

/* Hover effect on the scrollbar thumb */
::-webkit-scrollbar-thumb:hover {
  background-color: #550000; /* Darker color on hover */
}
