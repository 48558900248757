.fixed-sidebar.right {
    position: fixed;
    top: 0;
    right: 0;
    width: 55px;
    height: 100%;
    background: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 0 10px;
}
  
//   .chat-friendz {
//     padding: 10px;
//   }
  
  .chat-users {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .chat-users li {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .author-thmb {
    position: relative;
  }
  
  .author-thmb img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  
  .status {
    position: absolute;
    bottom: 5px;
    right: -5px;
    width: 10px;
    height: 14px;
    border-radius: 50%;
    border: 2px solid #fff;
  }
  
  .f-online {
    background: green;
  }
  
  .f-away {
    background: orange;
  }
  
  .f-offline {
    background: red;
  }
 .btn2 i{
    color: #fff;
 }
.chat-dialog .chat-head {
    display: flex;
    align-items: right;
    justify-content: space-between;
    background-color: #660032;
    color: white;
    padding: 10px;
  }
  
  .chat-content {
    height: 300px;
    overflow-y: auto;
  }
  
  .chat-list {
    padding: 10px;
  }
  
  .chat-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .chat-list li {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
  }
  
  .chat-list .me {
    flex-direction: row-reverse;
  }
  
  .chat-thumb img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .notification-event {
    max-width: 200px;
    background: #fff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .text-box {
    padding: 10px;
    background: #fff;
    border-top: 1px solid #e6e6e6;
  }
  
  .text-box textarea {
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    resize: none;
  }
  
  .add-smiles {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .add-smiles .smiles-bunch {
    position: absolute;
    bottom: 100%;
    left: 0;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: none;
    padding: 5px;
    z-index: 10;
  }
  
  .add-smiles:hover .smiles-bunch {
    display: block;
  }
  
  .add-smiles i {
    cursor: pointer;
  }
  .more{
    display: flex;
  }
  .close-mesage i{
    font-size: 13px;
    font-weight: 600;
  }
  .more-optns{
    margin-right: 20px;
  }
  .more-optns ul {
    display: none;
    list-style-type: none;
    text-align: left;
    background-color: #fff;
    color: #660032;
  }
  .more-optns ul li{
    font-size: 10px;
    font-weight: 300;
  }
  
  .more-optns:hover ul {
    display: block;
  }