.login-text{
    text-align: center;
    margin-left: 70px;
}

.login-img img{
    width: 85%;
}

.ligin-info h2{
    color: #ffffff;
    font-size: 45px;
}
.ligin-info h4{
    color: #ffffff;
    font-size: 40px;
}
.login-text p{
    color: #ffffff;
    font-size: 20px;
}

.welcome-box {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background-color: #f5f5f5; 
  background-image: url('../../../assets/images/slider-banner2.jpg'); 
  background-size: cover;
  background-position: center;
}

.text-effect {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;

  &:first-of-type {    // For increasing performance
                       // ID/Class should've been used.
                       // For a small demo
                       // it's okaish for now
    animation: showup 7s infinite;
  }

  &:last-of-type {
    width: 0px;
    animation: reveal 7s infinite;

    span {
      margin-left: -355px;
      animation: slidein 7s infinite;
    }
  }
}

@keyframes showup {
  0% {opacity: 0;}
  20% {opacity: 1;}
  80% {opacity: 1;}
  100% {opacity: 0;}
}

@keyframes slidein {
  0% { margin-left: -800px; }
  20% { margin-left: -800px; }
  35% { margin-left: 0px; }
  100% { margin-left: 0px; }
}

@keyframes reveal {
  0% {opacity: 0; width: 0px;}
  20% {opacity: 1; width: 0px;}
  30% {width: 355px;}
  80% {opacity: 1;}
  100% {opacity: 0; width: 355px;}
}

p {
  font-size: 12px;
  color: #999;
  // margin-top: 200px;
}
.text-color{
    color: #ffffff;
    font-size: 30px;
}

.text-style{
    font-size:4em;
    text-align:center;
//   height:200px;
//   width:600px;
  display:block;
  position:absolute;
  color:white;
  top: 175px;
    bottom:0;
    left:0;
    right:0;
  margin:auto;
}

$avatar-size: 32px;
$body-background: #353535;

a {
  text-decoration: none;
  color: #9ca0b1;
}

.wrapper {
  text-align: center;
  // margin-top: 266px;
  h1 {
    color: #fff;
    font-size: 35px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: Roboto;
    background: linear-gradient(to right,#095fab 10%, #25abe8 50%, #57d75b 60%);
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 1.5s linear infinite;
    display: inline-block;
  }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.text {
    position: absolute;
    width: 450px;
    left: 50%;
    margin-left: -225px;
    height: 40px;
    top: 50%;
    margin-top: -20px;
  }
  
  p {
    display: inline-block;
    vertical-align: top;
    margin: 0;
  }
  
.ptext-style {
    background: 50% 100% / 50% 50% no-repeat
                radial-gradient(ellipse at bottom, #fff, transparent, transparent);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 20px;
    font-family: Roboto;

    animation: reveal 3000ms ease-in-out forwards 200ms,
               glow 2500ms linear infinite 2000ms;
  
    @keyframes reveal {
      80%{
        letter-spacing: 4px;
      }
      100% {
        background-size: 300% 300%;
      }
    }
    @keyframes glow {
      40% {
        text-shadow: 0 0 1px #fff;
      }
    }
  }  