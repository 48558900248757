.sticky-left-grid {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Ensures elements are spaced vertically */
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100%; /* Full height to fit the parent container */
  min-height: 400px;
  overflow-y: auto;
}

.filter-footer {
  position: sticky;
  height: 38px;
  background-color: #660032;
  color: white;
}
.candidate-list-container { /* 8 candidates * approximate height of one CandidateCard */
  overflow-y: auto;
}
.candidates-list {
  overflow-y: auto;
  flex-grow: 1;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  height: 400px; 
}

.candidates-list::-webkit-scrollbar {
  width: 2px !important;
}

.candidates-list::-webkit-scrollbar-track {
  background-color: #f4f4f4 !important; /* Optional */
}

.candidates-list::-webkit-scrollbar-thumb {
  background-color: #660032 !important;
  border-radius: 4px;
}

.candidates-list::-webkit-scrollbar-thumb:hover {
  background-color: #4d0026 !important; /* Darker shade on hover */
}

.button-group-container {
  display: flex;
  justify-content: space-around;
  padding: 5px;
}

.source-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; /* Add this to support the ::after pseudo-element */
  border: 1px solid #660032 !important;
  color: #660032 !important;
  background-color: white;
  text-align: center;
  width: 90px;
}
.button-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}

.button-text {
  margin-bottom: 5px;
}

.source-button:hover {
  background-color: white !important;
  color: #660032;
}

.source-button.selected:hover {
  background-color: #660032 !important; /* Hover background color when active */
  color: white !important; /* Hover text color when active */
}

.source-button.selected {
  background-color: #660032; /* Active background color */
  color: white !important; /* Active text color */
}

.source-button.selected::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #660032;
  z-index: 1; /* Ensure it's visible above other elements */
}
.source-button:not(.disabled) {
  border: 1px solid #660032; /* Ensure 1px solid border for not selected */
}
  

  .source-button:last-child {
    margin-right: 0; /* Remove margin from the last button */
  }

  .apply-filter{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .apply-filter-btn{
    color: #fff;
    background-color: #660032;
    border: none;
    border-radius: 10px;
    font-size: 12px;
    font-family: 'Roboto';
    padding: 10px;
  }
 .count-candidate{
  color: "#660032";
  display: "flex";
  gap: "10px";
  font-size: 12px !important;
  font-family: 'Roboto' !important;
 }