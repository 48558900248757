.dialog-title {
  background-color: #660032;
  color: white;
  /* padding-left: 20px; */
  display: flex;
  position: relative;
  font-size: 18px;  
}

.resume-dialog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f4f5f7;
}

.resume-profile-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}
.resume-data{
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin: 0 !important;
  gap: 5px;
  padding: 5px;
  display: flex;
}
.resume-profile-left-column, .resume-profile-right-column {
  padding: 5px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.resume-profile-left-column {
  position: relative;
  /* padding-right: 16px; */
}

.personal-item, .education-item, .experience-item {
  list-style-type: none;
  padding: 5px;
  border-radius: 5px;
  /* margin-bottom: 10px; */
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}

.resume-skills-list {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-family: 'Roboto';
  font-size: 12px;
}

.resume-skill-item {
  padding: 5px;
  background-color: #e0e0e0;
  border-radius: 20px;
  font-family: 'Roboto';
  font-size: 12px;
}

.dialog-footer {
  background-color: #660032;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
}

.dialog-footer-button {
  color: white !important;
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}

.resume-data-underline{
  border-bottom: 1px solid #660032;
  width: fit-content;
  padding-right: 5px;
  color: #660032;
}


.cv-access-dialog .MuiDialog-paper::-webkit-scrollbar {
  width: 2px !important;
}

.cv-access-dialog .MuiDialog-paper::-webkit-scrollbar-track {
  background-color: #f4f4f4 !important; /* Optional */
}

.cv-access-dialog .MuiDialog-paper::-webkit-scrollbar-thumb {
  background-color: #660032 !important;
  /* border-radius: 4px; */
}

.cv-access-dialog .MuiDialog-paper::-webkit-scrollbar-thumb:hover {
  background-color: #4d0026 !important; /* Darker shade on hover */
}
