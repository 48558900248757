/* src/UserDashboard.css */
.user-dashboard-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .superadmin-dashboard-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
   .user-dashboard-icon {
    font-size: 2em;
    color: #fff;
    position: absolute;
    top: 10px;
    right: 10px;
  }
.bg-c-blue {
  background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg,#FF5370,#ff869a);
}

.bg-c-red {
  background: linear-gradient(45deg, red, #ff8080); /* Example for red gradient */
}

.bg-c-violet {
  background: linear-gradient(45deg, violet, #d580ff); /* Gradient for violet */
}
.card-block{
  position: relative; /* Ensures circles can be positioned inside the card */
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 20px;
  height: 100%;
  background-color: #ff5370; /* Example background color, adjust according to need */
  color: white;
  overflow: hidden; 
  cursor: pointer;

}
.circle {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1); /* Light translucent white color */
  z-index: 0; /* Make sure the circle is behind the text */
}
.circle.circle-1 {
  width: 200px; /* Adjust size */
  height: 200px; /* Adjust size */
  top: -50px; /* Adjust position */
  right: -50px; /* Adjust position */
}

/* Circle at the bottom-right */
.circle.circle-2 {
  width: 150px;
  height: 150px;
  bottom: -50px;
  left: -30px;
}
.title-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures vertical alignment */
  border-radius: 10px;
}

.f-right {
  margin-left: auto; /* Pushes the icon to the right */
}


  .white-text {
    color: #fff; /* White text color */
}

  .divider-horizontal {
    margin-bottom: 10px;
    height: 1px;
    background-color: #ffffff;
    border-radius: 2px;
  }

  .status-wrapper {
    position: absolute;
    top: 10px; /* Adjust this based on the padding/margin of your card */
    right: 10px; /* Adjust this as needed */
  }
  .status-badge {
    padding: 5px 10px;
    border-radius: 12px;
    color: white;
    /* font-size: 12px; */
  }
  
  .status-badge.active {
    background-color: white;
    color: green; /* New color for enabled status */
  }
  
  .status-badge.inactive {
    background-color: white;
    color: red;/* New color for disabled status */
  }
  
  .performance-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
  }
  .profile-performance-card {
    background-color: #fff;
    color: #660032;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    flex: 1;
    /* display: flex; */
    flex-direction: column;
    padding: 10px;
    margin-bottom: 16px;
  }
  .profile-perforance-header {
    background-color: #660032;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #660032;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
  
  .profile-perforance-header h3 {
    color: #fff;
    margin: 0;
    padding: 10px;
  }
  
  .export-btn {
    background-color: #660032;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .export-btn:hover {
    background-color: #550025;
  }
  
  .grid-header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    margin-bottom: 10px;
    padding: 5px;
  }
  
  .revenue-bar {
    position: relative;
    height: 5px;
    background-color: #e0e0e0;
    width: 100%;
    margin-top: 5px;
  }
  
  .bar {
    height: 5px;
    background-color: #00AA00;
    position: absolute;
    left: 0;
    top: 0;
  }
  .profile-performance-icon {
    color: white;
    border-radius: 50%;
    font-size: 13px;
    display: flex;
    justify-content: center;  /* Centers horizontally */
    align-items: center;      /* Centers vertically */
    height: 30px;             /* Adjust as needed */
    width: 30px; 
  }
  .bg-color1 {
    background: linear-gradient(45deg, #FF5F6D, #FFC371);  /* Red to Orange */
  }
  
  .bg-color2 {
    background: linear-gradient(45deg, #36D1DC, #5B86E5);  /* Light Blue to Purple */
  }
  
  .bg-color3 {
    background: linear-gradient(45deg, #FF512F, #DD2476);  /* Dark Pink to Red */
  }
  
  .bg-color4 {
    background: linear-gradient(45deg, #654EA3, #EA6060);  /* Purple to Light Red */
  }
  
  .bg-color5 {
    background: linear-gradient(45deg, #F7971E, #FFD200);  /* Orange to Yellow */
  }
  
  .bg-color6 {
    background: linear-gradient(45deg, #7F00FF, #E100FF);  /* Purple to Pink */
  }
  
  .bg-color7 {
    background: linear-gradient(45deg, #11998E, #38EF7D);  /* Teal to Green */
  }
  

  .profile-charts {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 20px;
  }
.profile-performance-graph{
  margin-top: 10px !important;
}
#gauge-chart .gauge-chart-text {
  display: none !important;
}
.dashboard-graph-title{
  color: #660032;
}