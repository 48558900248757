.candidate-card {
  /* width: 100%; */
  max-width: 250px; /* Set a max-width to control the card width */
  height: 170px; 
  /* transition: transform 0.2s; */
  display: flex;
  flex-direction: column;
  border: solid 1px #660032;
  overflow: hidden; 
  text-overflow: ellipsis;
}

/* .candidate-card:hover {
  transform: scale(1.05);
} */

.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  background-color: #660032 !important;
  padding: 10px;
}

.card-header .avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-left: 5px;
  flex-shrink: 0; 
}

.card-header .candidate-name {
  flex-grow: 1;
  /* font-size: 10px; */
  /* padding-left: 7px; */
  color: white;
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for overflow text */
  font-size: 12px !important;
  font-family: 'Robot';
  margin-left: 10px;
}

.card-header .icon-button {
  font-size: 25px !important;
  padding: 4px;
  color: white !important;
}
.card-content{
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.card-contain-container {
  display: flex;
  align-items: center;
  gap: 8px; /* Adjust the gap as needed */
  font-size: 12px !important;
  font-family: 'Roboto';
  margin-left: 3px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
.job-title-label {
  font-weight: bold;
  /* margin-left: 4px; */
}
.job-title-value {
  width: 60%; /* Adjust this based on your layout */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.job-title-value-skills{
  width: 1px; /* Adjust this based on your layout */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.see--more{
  margin-left: 4px;
  color: #660032;
}