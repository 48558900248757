
.pdf-container {
  position: relative;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;
  min-height: 100vh;
}

.pdf-button-container {
  display: flex;
  justify-content: flex-end;
  /* margin-top: 10px; */
}

.pdf-button-container .GeneratePdf-button {
  color: #660032 !important;
}