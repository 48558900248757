.forgot-page {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  min-height: 100vh; /* Ensures the div takes up full viewport height */
  background-color: #f8f9fa; /* Optional: add a background color */
}

.forgot-background {
  justify-content: center;
  align-items: center;
  background-image: url('./../../assets/images/t-1.jpg');
  background-size: cover;
  background-position: center;
}

.forgot-box{
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 20px; */
  padding-bottom: 40px;
  margin: auto;
}

.forgot-container {
  background-color: #ffffff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 28px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
  padding: 20px;
  max-width: 800px; /* Maximum width of the container */
  width: 90%; /* Width that allows it to shrink on smaller screens */
  overflow: hidden; /* Prevents content from overflowing the container */
}

.forgot-container .forgot-card {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16); /* Add a subtle shadow */
  border-radius: 24px; /* Optional: Add rounded corners */
}

.forgot-input{
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 0;
  box-sizing: border-box;
  border-radius: 9999px;
  box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
  color: #6c6c6c;
  font-size: 14px;
  font-family: "Roboto";
  /* line-height: 44px; */
  outline: none;  
  /* border-bottom: 1px solid #ab3a71; */
  padding-right: '2.5rem';
  position: relative; /* Ensure the input field's relative position */
}
.eye-icon{
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px; /* Adjust icon size as needed */
  color: #660032;
}