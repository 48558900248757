/* sqanit-form css */
.sqan-it-page{
  margin-bottom: 20px;
}
.scan-it-paper{
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* margin: 5px; */
  /* padding: 10px; */
  height: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  }

.sqan-it-form-title {
    /* font-size: 10px !important; */
    color: #660032;
    font-weight: bold;
    margin-top: 8px !important; /* equivalent to mt: 1 */
  }
  .sqan-it-form-textfield {
    padding: 10px !important; /* Adds padding inside the input field */
    width: 100%;
    box-sizing: border-box;
    /* padding-right: '30px'; */
    font-size: 12px;
    height: 35px;
    margin-top: 20px;
    /* margin-bottom: 10px; */
    border-radius: 8px;
    border: none;
    border: 1px solid #660032 !important;
  }
  .sqan-it-form-textfield:-webkit-autofill {
    background-color: transparent !important; /* Remove background color */
    color: fieldtext !important; /* Apply text color */
  }
  
  .sqan-it-form-textfield:-moz-autofill {
    background-color: transparent !important; /* Remove background color */
    color: fieldtext !important; /* Apply text color */
  }
  
  .sqan-it-form-textfield:-internal-autofill-selected {
    background-color: transparent !important; /* Remove background color */
    color: fieldtext !important; /* Apply text color */
  }
  
  
  .sqan-it-form-footer {
    display: flex;
    justify-content: space-around;
    background-color: #660032;
    padding: 10px;
    margin-top: 20px; 
    color: white;
    /* cursor: pointer; */
  }
  .sqan-it-form-button {
    min-width: 0px !important;
    background-color: transparent !important;
    color: white !important;
    cursor: pointer;
    border: none;
    border-radius: none;
    box-shadow: none !important;
  }
  .sqan-it-form-button:active {
    box-shadow: none; /* Remove box-shadow when the button is active (clicked) */
  }
  .disabled-button {
    /* Light gray for disabled buttons */
    color: #ffffff !important; /* Gray text */
    /* cursor: not-allowed !important; */
    opacity:0.7;
    
  }
  .selected-files {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    margin-top: 7px;
    /* height: 100%; Ensures it takes up the available height */
    text-align: center; /* Ensures the text itself is centered */
  }
  
  .sqan-it-form-selected-files {
    /* font-size: 12px; */
    font-weight: bold;
  }

  .image-box{
    display: flex;
  justify-content: center; /* Horizontally centers the image */
  align-items: center;     /* Vertically centers the image */
  height: 100%;            /* Ensures the container takes full height */
  width: 100%;             
  }
.sqan-it-image{
  max-width: 70%;         /* Ensures the image doesn't overflow */
  max-height: 70%; 
}


  /* sqan it cards css and source button css*/
  .sqan-it-button-group-container {
    display: flex;
    justify-content: space-around;
    /* margin-bottom: 15px; */
  }

  .sqan-it-source-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative; /* Add this to support the ::after pseudo-element */
    border: 1px solid #660032 !important;
    color: #660032 !important;
    background-color: white;
    text-align: center;
    font-size: 12px !important;
    font-family: 'Roboto' !important
    ;
    /* margin-top: 5px; */
    /* width: 100%; */
    /* margin-bottom: 5px; */
    height: 30px;
    /* width: 80px; */
  }
  
  .sqan-it-source-button.selected {
    background-color: #660032; /* Active background color */
    color: white  !important; /* Active text color */
  }
  .sqan-it-source-button.selected::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #660032;
    z-index: 1; /* Ensure it's visible above other elements */
  }

  .sqan-it-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Ensures cards align side by side with space between */
    /* gap: 5px; Adds some spacing between the cards */
  }

  .sqan-it-card {
    width: calc(50% - 10px); /* Two cards side by side, with some spacing */
    /* max-width: 300px; */
    /* height: auto; */
    margin-bottom: 5px;
    /* transition: transform 0.2s; */
    display: flex;
    flex-direction: column;
    border: solid 1px #660032;
    overflow: auto; /* Ensures content doesn't overflow the card */
    object-fit: contain; /* Ensures the aspect ratio is maintained */
    display: block;
  }
  
  /* .sqan-it-card:hover {
    transform: scale(1.05);
  } */
  
  .sqan-it-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 5px; */
    background-color: #660032 !important;
    padding: 5px;
  }
  
  .sqan-it-card-header .sqan-it-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 10px; */
    margin-left: 5px;
  }
  
  .sqan-it-card-header .candidate-name {
    flex-grow: 1;
    /* font-size: 10px; */
    padding-left: 7px;
    color: white;
    white-space: nowrap; /* Prevents text wrapping */
    overflow: hidden; /* Hides overflowing text */
    text-overflow: ellipsis; /* Adds ellipsis for overflow text */
    font-size: 12px !important;
    font-family: 'Roboto' !important;
  }
  
  .sqan-it-card-content {
    /* padding-left: 5px; */
    background-color: white;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; Ensures even spacing of content */
    text-overflow: ellipsis !important;
    padding: 8px;
  }
  .sqan-it-card-space{
    padding-top: 0 !important;
    /* margin-bottom: 5px; */
  }
  .sqan-it-card-info-label {
    /* font-size: 10px !important; */
    color: black;
    flex: 1;
    text-align: left;
    white-space: nowrap; /* Prevents text wrapping */
    overflow: hidden; /* Hides overflowing text */
    text-overflow: ellipsis; /* Adds ellipsis for overflow text */
    font-size: 12px !important;
    font-family: 'Roboto' !important;
  }
  
  .sqan-it-value {
    /* font-size: 10px !important; */
    color: black !important;
    flex: 1;
    text-align: left;
    /* white-space: nowrap; Prevents text wrapping */
    overflow: hidden;  /*Hides overflowing text */
    text-overflow: ellipsis; /* Adds ellipsis for overflow text */
    display: block; /* Ensures block display */
    width: 100%; /* Ensures the width fits within the parent container */
    max-width: 100%; /* Ensures the max-width fits within the parent container */
    font-size: 12px !important;
    font-family: 'Roboto' !important;
  }
  .sqan-it-see-more {
    /* font-size: 10px !important; */
    color: #660032 !important;
    cursor: pointer;
    text-decoration: underline;
  }
  
/* sqanit last column information css */
.scan-it-profile-container{
  background-color: #f2f2f2;
}

.profile-personal-item, .profile-education-item, .profile-experience-item {
  list-style-type: none;
  padding: 10px;
  /* border-radius: 5px; */
  margin-bottom: 5px;
  background-color: #fff !important;
  border-radius: 10px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  overflow: auto;  /*Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for overflow text */
}

.profile-skills-list {
  padding: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: #fff !important;
  border-radius: 10px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
  font-size: 12px;
  font-family: 'Roboto';
}

.profile-skill-item {
  padding: 8px;
  background-color: #f2f2f2;
  border-radius: 20px;
  font-size: 12px;
  font-family: 'Roboto';
}
/* sqanit drawaer css */

  .scan-it-drawer {
    max-width: 1100px !important; /* Ensure max width is applied */
    height: 81vh !important; /* Ensure height is applied */
    background-color: #660032 !important; /* Ensure background color is applied */
    position: fixed !important; /* Ensure position is applied */
    top: 10% !important; /* Ensure top is applied */
    bottom: 10% !important; /* Ensure bottom is applied */
    right: 0 !important; /* Ensure right is applied */
    overflow: auto !important; /* Ensure overflow is applied */
    display: flex !important;
    flex-direction: column !important;
    /* padding: 2px !important; */
  }
  
  .scan-it-drawer::-webkit-scrollbar {
    width: 0px !important;
    display: none;
  }

  .scan-it-close-icon {
    margin-top: 1px;
    color: white;
    cursor: pointer;
    position: absolute;
    right: 5px;
    font-size: 16px !important;
    /* margin-top: 5px; */
  }

  .scan-it-drawer-paper {
    margin-top: 4px;
    padding: 20px;
    height: 75vh;
    overflow: auto;
  }
  .scan-it-stepper-drawer-paper{
    margin-top: 4px;
    padding: 20px;
    height: 75vh;
    overflow: auto;
    width: 600px;
  }
  .scan-it-drawer-paper::-webkit-scrollbar {
    width: 4px !important;
  }
  
  .scan-it-drawer-paper::-webkit-scrollbar-track {
    background-color: #f4f4f4 !important; /* Optional */
  }
  
  .scan-it-drawer-paper::-webkit-scrollbar-thumb {
    background-color: #660032 !important;
    /* border-radius: 4px; */
  }
  
  .scan-it-drawer-paper::-webkit-scrollbar-thumb:hover {
    background-color: #4d0026 !important; /* Darker shade on hover */
  }
  .scan-it-button-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  
  .scan-it-button-group {
    display: flex;
  }
  
  .scan-it-button {
    /* font-size: 12px; */
    padding: 8.5px 5px;
    min-width: auto;
    border: #660032 solid 1px;
    margin-left: 5px;
    font-size: 12px !important;
    font-family: 'Roboto' !important;
  }
  .scan-it-button:hover{
    background-color: #fff;
    color: #660032;
  }
  .scan-it-button.active {
    color: #fff;
    background-color: #660032;
  }
  .scan-it-button.active:hover {
    color: #fff;
    background-color: #660032;
  }

  .scan-it-button:not(.active) {
    color: #660032;
    background-color: transparent;
  }
  
  .scan-it-delete-button-container {
    display: flex;
  }

  .scan-it-delete-button {
    padding: 2.5px 4px;
    min-width: auto;
    margin-left: 5px;
  }
  
  .scan-it-delete-button.enabled {
    color: #660032;
    border: #660032 solid 1px;
  }
  
  .scan-it-delete-button.disabled {
    color: #dc95b7;
    border: #dc95b7 solid 1px;
  }




/* sqanit stepper form css */
.step-content-grid {
    /* height: 40vh; */
    /* overflow: auto; */
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-textfield{
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}
/* styles.css */
.custom-textfield input::placeholder {
  color: black; /* Change the placeholder color to black */
  opacity: 1;   /* Ensure the placeholder color applies fully */
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}

.info-value{
  font-size: 12px;
  font-family: 'Roboto';
}
.custom-textfield .MuiInputLabel-root.Mui-focused {
  color: #660032; /* Label color when focused */
}

.custom-textfield .MuiInput-underline:before {
  border-bottom-color: #660032; /* Default border color */
}

.custom-textfield .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom-color: #660032; /* Hover border color */
}

.custom-textfield .MuiInput-underline:after {
  border-bottom-color: #660032; /* Focused border color */
}

/* For the Input field */
.custom-textfield {
  background-color: #f4f4f4c8 !important;
  /* font-size: 12px; */
  font-weight: bold;
  padding-left: 5px;
}
.info-label {
  font-size: 12px;
  font-family: 'Roboto';
}
.step-content-input-select{
  font-size: 12px !important;
  font-family: 'Roboto';
}
.step-content-input-select .MuiSelect-icon{
  color: black !important;
}

.menu-item{
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}

/* Focused label color */
.step-content-label{
  font-size: 12px !important;
  font-family: 'Roboto';
}
.step-content-label.Mui-focused {
    color: #660032 !important; /* Focused label color */
}

/* Border color for before and after the select input */
.step-content-input-select {
    font-size: 12px !important;
    font-family: 'Roboto';
    padding-left: 5px;
}

.step-content-input-select:hover:not(.Mui-disabled):before {
    border-bottom: #660032; /* Hover border color */
}

/* Select input before and after focus for a uniform border look */
.step-content-input-select:before, 
.step-content-input-select:after {
    border-bottom: #660032 !important; /* Ensure #660032 for all sides */
}

.step-content-input-select.MuiInputBase-root {
    border-bottom: 1px solid #660032; /* Border for all sides */
}

.step-content-input-select .MuiOutlinedInput-root {
    border-bottom: #660032;
}

.step-content-input-select .MuiOutlinedInput-root:hover fieldset {
    border-bottom: #660032;
}

.step-content-input-select .MuiOutlinedInput-root.Mui-focused fieldset {
    border-bottom: #660032;
}


.step-content-textfield .MuiInputLabel-root.Mui-focused {
    color: #660032;
    font-size: 12px !important;
    font-family: 'Roboto' !important;
}

.step-content-textfield .MuiOutlinedInput-root fieldset {
    border-color: #660032;
}

.step-content-textfield .MuiOutlinedInput-root:hover fieldset {
    border-color: #660032;
}

.step-content-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #660032;
}

.sqan-it-button-group-container-2 {
  display: flex;
  /* justify-content: space-around; */
  /* margin-bottom: 15px; */
  /* border: 1px solid #660032 !important; */
  width: 100%;
  margin-bottom: 5px;
}
.sqan-it-source-button-2 {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  position: relative; /* Add this to support the ::after pseudo-element */
  border: 1px solid #660032 !important;
  color: #660032 !important;
  background-color: white;
  text-align: center;
  font-size: 12px !important;
  font-family: 'Roboto' !important;
  margin-top: 5px;
  /* margin-bottom: 5px; */
  width: 100%;
  height: 43px;
  text-transform: uppercase;
}
.sqan-it-source-button-2:hover{
  background-color: white !important;
  color: #660032 !important;
}
.sqan-it-source-button-2.selected {
  background-color: #660032; /* Active background color */
  color: white  !important; /* Active text color */
}
.sqan-it-source-button-2.selected:hover{
  background-color: #660032 !important;
  color: white !important;
}
.count-div{
  display: inline-flex;
  background-color: green !important;
  border-radius: 50%;
  color: white;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 25px;
  width: 25px;
  margin-left: 10px;
}
/* Sqanit stepper css */
.scan-it-stepper .MuiStepIcon-completed {
  color: #660032;
}

.scan-it-stepper .MuiStepLabel-completed {
  color: #660032;
}

.scan-it-stepper-buttons {
  display: flex !important;
  margin-top: 16px; /* Corresponds to "mt: 2" in the original sx */
  justify-content: flex-end !important;
  gap: 5px;
}

.scan-it-stepper-buttons-start {
  justify-content: flex-end !important;
}

.scan-it-stepper-buttons-between {
  justify-content: space-between;
}

.scan-it-button-back {
  color: #fff !important;
  background-color: #660032!important;
}

.scan-it-button-back:hover {
  background-color: #660032;
}

.scan-it-button-next {
  background-color: #660032 !important;
  color: #fff!important;
}

.scan-it-button-next:hover {
  background-color: #660032 !important;
}

.scan-it-button-next.Mui-disabled {
  background-color: #a8a1a1!important;
  color: #660032;
}

.scan-it-button-finish {
  background-color: #660032!important;
  color: #fff !important;
}

.scan-it-button-finish:hover {
  background-color: #660032 !important;
}

.scan-it-button-finish.Mui-disabled {
  background-color: #c1c1c1 !important;
  color: #660032 !important;
}

.sqan-it-card .MuiCheckbox-root{
  color: white !important;
  font-family: 'Roboto' !important;
}

.sqan-it-card .MuiCheckbox-root.Mui-checked{
  color: white !important;
  font-family: 'Roboto' !important;
}

/* tablestyles */
/* Table Head Styles */
.table-head {
  color: #660032;
  padding-left: 10px;
}

.table-head-small {
  color: #660032;
  padding-left: 10px;
  max-width: 60px;
}

/* Tooltip and Cell Styles */
.tooltip-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
  width: 60px;
}

.cell-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cell-small {
  max-width: 20px;
}

.cell-medium {
  max-width: 50px;
}

.cell-large {
  max-width: 400px;
}

.cell-fitment {
  max-width: 20px;
}

.cell-status {
  max-width: 30px;
}

/* Icon Button Styles */
.icon-button {
  color: #660032;
}

.skeleton-text {
  width: 100px;
}

/* External CSS: custom-scrollbar styling */
/* Custom scrollbar for table body */
/* Target the table body and apply scrollbar styles */
/* Custom scrollbar styling */
.MUIDataTable-responsiveBase {
  scrollbar-width: thin !important;
    scrollbar-color: #660032 !important;
}


/* Disable scrollbar for pagination */
.MuiTablePagination-root::-webkit-scrollbar {
  width: 0px !important;
  display: none;
  scrollbar-width: none;
}




/* Base styling for the containers */
.scan-it-paper-1, .scan-it-paper-2 {
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
  /* padding: 10px; */
  scrollbar-width: 2px; /* Hide scrollbar in Firefox */
}
.scan-it-paper-1{
  padding: 10px;
}
/* Hide scrollbar by default */
.scan-it-paper-1::-webkit-scrollbar, .scan-it-paper-2::-webkit-scrollbar {
  width: 0; /* Hide scrollbar */
}

/* Show custom scrollbar on hover */
.scan-it-paper-1:hover::-webkit-scrollbar, 
.scan-it-paper-2:hover::-webkit-scrollbar {
  width: 2px; /* Reduced scrollbar width */
}

/* Custom scrollbar track */
.scan-it-paper-1::-webkit-scrollbar-track, 
.scan-it-paper-2::-webkit-scrollbar-track {
  background: #f1f1f1; /* Optional background for scrollbar track */
  border-radius: 4px;
}

/* Custom scrollbar thumb (the draggable part) */
.scan-it-paper-1::-webkit-scrollbar-thumb, 
.scan-it-paper-2::-webkit-scrollbar-thumb {
  background-color: #660032; /* Scrollbar color */
  border-radius: 4px; /* Optional rounded corners for scrollbar */
}

/* Scrollbar thumb hover effect */
.scan-it-paper-1::-webkit-scrollbar-thumb:hover, 
.scan-it-paper-2::-webkit-scrollbar-thumb:hover {
  background-color: #4d0024; /* Darker color on hover */
}
