/* Other existing styles */
.footer-section {
  background-color: #660032; /* Maroon background color */
  color: white;
  padding-top: 20px;
}

.footer-top {
  margin-left: 80px;
  margin-right: 80px;
}


.footer-logo-image {
  /* width: 100px; */
  height: 70px;
  /* margin-left: 100px; */
}

.footer-social-icons{
  display: flex;
  /* justify-content: center !important; */
  /* align-items: center !important; */
  list-style: none;
  /* margin-right: 50px; */
}

.footer-social-link {
  color: #ffffff;
  font-size: 24px;
  transition: color 0.3s;
}

.footer-divider {
  width: 100%;
  height: 0.5px; /* Adjust thickness as needed */
  background-color: white; /* Or another color if you prefer */
  margin: 20px 0; /* Adjust spacing as needed */
}

.footer-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-description {
  color: #ffffff;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 12px;
  font-family: 'Roboto';
}

.footer-link-item {
  margin-bottom: 10px;
}

.footer-links {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 12px;
  font-family: 'Roboto';
}

.footer-links:hover {
  color: #ddd;
}

.footer-subscribe-form .footer-input {
  width: calc(100% - 100px);
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  font-family: 'Roboto';
}

.footer-subscribe-form .footer-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: white;
  color: #800020;
  font-weight: bold;
  font-size: 12px;
  font-family: 'Roboto';
}

.footer-bottom {
  background-color: #54022a;
  padding: 20px 0;
}

.footer-rights {
  margin: 0;
  font-size: 12px;
  font-family: 'Roboto';
  color: #fff;
}

.footer-policies {
  display: flex;
  align-items: center;
}

.footer-policy-link {
  color: white;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s;
  font-family: 'Roboto';
  font-size: 12px;
}

.footer-policy-separator {
  color: white;
}
