.history-scan-it-drawer {
    max-width: 600px !important; /* Ensure max width is applied */
    height: 80vh !important; /* Ensure height is applied */
    background-color: #660032 !important; /* Ensure background color is applied */
    position: fixed !important; /* Ensure position is applied */
    top: 10% !important; /* Ensure top is applied */
    bottom: 10% !important; /* Ensure bottom is applied */
    right: 0 !important; /* Ensure right is applied */
    overflow: auto !important; /* Ensure overflow is applied */
    display: flex !important;
    flex-direction: column !important;
    /* padding: 10px !important; */
  }

  .history-scan-it-drawer .history-scan-it-close-icon {
    margin-top: 1px;
    color: white;
    cursor: pointer;
    position: absolute;
    right: 5px;
    font-size: 16px !important;
    /* margin-bottom: 5px; */
  }
 .history-scan-it-drawer-paper {
    margin-top: 4px;
    padding: 20px;
    height: 76vh;
    overflow: auto;
  }
.history-step-content-grid {
    /* height: 40vh; */
    overflow: auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.history-scan-it-drawer-paper::-webkit-scrollbar {
    width: 2px !important;
  }
  
  .history-scan-it-drawer-paper::-webkit-scrollbar-track {
    background-color: #f4f4f4 !important; /* Optional */
  }
  
  .history-scan-it-drawer-paper::-webkit-scrollbar-thumb {
    background-color: #660032 !important;
  }
  
  .history-scan-it-drawer-paper::-webkit-scrollbar-thumb:hover {
    background-color: #4d0026 !important; /* Darker shade on hover */
  }
  
/* For the TextField container */
.history-custom-textfield .MuiInputLabel-root.Mui-focused {
    color: #660032; /* Label color when focused */
  }
  
  .history-custom-textfield .MuiInput-underline:before {
    border-bottom-color: #660032; /* Default border color */
  }
  
  .history-custom-textfield .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom-color: #660032; /* Hover border color */
  }
  
  .history-custom-textfield .MuiInput-underline:after {
    border-bottom-color: #660032; /* Focused border color */
  }
  
  /* For the Input field */
  .history-custom-textfield {
    background-color: #f4f4f4;
    font-size: 12px;
    font-weight: bold;
    padding-left: 5px;
  }
  
.info-label {
    font-size: 12px;
    font-family: 'Roboto';
}

.info-value {
    font-size: 12px !important;
    font-family: 'Roboto' !important;
}
.menu-item{
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}
.history-step-content-label{
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}
.history-step-content-input-select{
  font-size: 12px !important;
  font-family: 'Roboto' !important;
}
.history-step-content-input-select .MuiSelect-icon {
    color: black !important; /* Change dropdown icon color to black */
  }
/* Focused label color */
.history-step-content-label.Mui-focused {
    color: #660032 !important; /* Focused label color */
}

/* Border color for before and after the select input */
.history-step-content-input-select {
    font-size: 10px !important;
    font-weight: bold;
    padding-left: 5px;
}

.history-step-content-input-select:hover:not(.Mui-disabled):before {
    border-bottom: #660032; /* Hover border color */
}

/* Select input before and after focus for a uniform border look */
.history-step-content-input-select:before, 
.history-step-content-input-select:after {
    border-bottom: #660032 !important; /* Ensure #660032 for all sides */
}

.history-step-content-input-select.MuiInputBase-root {
    border-bottom: 1px solid #660032; /* Border for all sides */
}

.history-step-content-input-select .MuiOutlinedInput-root {
    border-bottom: #660032;
}

.history-step-content-input-select .MuiOutlinedInput-root:hover fieldset {
    border-bottom: #660032;
}

.history-step-content-input-select .MuiOutlinedInput-root.Mui-focused fieldset {
    border-bottom: #660032;
}


.history-step-content-textfield .MuiInputLabel-root.Mui-focused {
    color: #660032;
}

.history-step-content-textfield .MuiOutlinedInput-root fieldset {
    border-color: #660032;
}

.history-step-content-textfield .MuiOutlinedInput-root:hover fieldset {
    border-color: #660032;
}

.history-step-content-textfield .MuiOutlinedInput-root.Mui-focused fieldset {
    border-color: #660032;
}

.history-scan-it-button-back {
    color: #fff !important;
    background-color: #660032!important;
  }

  .history-scan-it-button-next {
    background-color: #660032 !important;
    color: #fff!important;
  }
  .history-scan-it-button-next.Mui-disabled {
    background-color: #a8a1a1!important;
    color: #660032;
  }
.history-scan-it-button-finish {
    background-color: #660032!important;
    color: #fff !important;
  }
  .MuiTablePagination-actions .MuiIconButton-root {
    color: white; /* Change next icon color to white */
  }

  .history-scan-it-table-topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }


  .filter-modal{
    display: flex;
    justify-content: center;
    align-items: center;
    border: none !important;
  }

  .filter-modal-container{
    background-color: white;
  }
  
  .filter-modal-title{
    display: flex;
    justify-content: space-between;
    align-content: center;
    font-size: 16px;
    color: white;
    padding: 10px;
    background-color: #660032;
  }

  .filter-modal-content {
    padding: 20px;
  }

  .filter-modal-button{
    background-color: #660032;
    color: white;
    padding: 5px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
  }

  .filter-modal-input{
    width: 100%;
    padding: 5px;
  }

  .filter-modal-input-date{
    width: 75%;
    padding: 5px;
  }

  .filter-modal-select{
    width: 100%;
    padding: 7.5px;
  }
 
