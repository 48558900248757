.user-profile {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background-color: white;
    font-family: Roboto;
  }
  
  .profile-header {
    position: relative;
    width: 100%;
  }
  
  .header-image {
    width: 100%;
    height: 175px;
    object-fit: cover;
  }
  
  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid white;
    position: absolute;
    bottom: -50px;
    left: calc(50% - 60px);
  }

  .profile-summary{
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  
  .profile-page-tabs{
    padding: 10px;

  }

  .profile-page-tab {
    // font-weight: 600;
    font-size: 12px;
    color: gray
  }
  
  .profile-page-tab.Mui-selected {
    color: #660032 !important; /* Active tab color */
  }
  
  .profile-page-indicator {
    background-color: #660032 !important; /* Indicator color */
  }

.user-profile-buttons {
  // background-color: #f5f5f5;
  // border-radius: 30px !important;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 10px; /* Optional: Adds space between buttons */
}

.user-profile-info-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white !important;
  color: #660032 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // border-radius: 50% !important;
  margin-bottom: 10px;
  margin-bottom: 15px !important;
  align-items: center;
  justify-content: center;
  border: none !important;
  width: 70%;
}

.user-profile-info-btn:hover {
  background-color: #660032;
  color: white;
}
.user-profile-info{
  border-radius: 20px;
  background-color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
}
.icon-style{
  font-size: 13px !important;
  color: #660032 !important;
}