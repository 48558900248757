.bar::-webkit-scrollbar {
  width: 5px;
  /* Adjust this value to make the scrollbar thinner */
}

.bar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Rounded corners for the thumb */
}

.modalBody {
  font-size: medium;
}

.breadCrumb-container{
  margin-bottom: 15px;
}

.breadCrumb-separator{
  color: #660032;
}

.breadCrumb-link{
  color: #660032;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Roboto';
  display: flex;
  align-items: center;
  justify-content: center;
}

.breadCrumb-active{
  color: #660032;
  font-size: 12px !important;
  font-family: 'Roboto' !important;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: default;
} 

