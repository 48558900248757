.new-requirement-form-label {
  color: #660032;
}

.job-requirement-container {
  background-color:white;
  flex-grow: 1; 
  overflow-y: hidden;
  border-radius: 10px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
  transition: overflow-y 0.3s;
}

.job-requirement-container:hover {
  overflow-y: auto;
}

.job-requirement-header {
  flex-grow: 1;
  text-align: center;
  padding-top: 10px;
}

.job-requirement-content {
  overflow-y: auto;
  height: calc(100% - 40px);
  /* Adjust height if necessary */
}


.form-title {
  text-align: center;
  /* font-size: 16px; */
  color: white;
  background-color: #660032;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.form-title::after {
  content: "";
  position: absolute;
  bottom: -9px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #660032;
}

.close-icon {
  color: white;
}

.user-management-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  margin-bottom: 5px;
}

.user-management-title-icon {
  background-color: #660032;
  margin-left: 10px;
  padding: 10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.user-management-title-icon-add{
  color: #ffffff;
} 

.tod-container{
  margin-bottom: 20px;
}

.no-spinner::-webkit-inner-spin-button, 
.no-spinner::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.no-spinner {
  -moz-appearance: textfield;
}

.error{
  color: red;
}

.common-font{
  font-size: 12px !important; 
  font-weight: lighter !important;
}

/* For non-WebKit browsers, you can use this (currently more limited) */
.tss-1cdcmys-MUIDataTable-responsiveBase {
  scrollbar-color: #660032 #f0f0f0; /* thumb and track colors */
  scrollbar-width: none; /* Make scrollbar thinner */
}

.filter-modal{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}

.filter-modal-container{
  background-color: white;
}

.filter-modal-title{
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-size: 16px;
  color: white;
  padding: 10px;
  background-color: #660032;
}

.filter-modal-content {
  padding: 20px;
}

.filter-modal-button{
  background-color: #660032;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
}

.filter-modal-input{
  width: 100%;
  padding: 5px;
}

.filter-modal-input-date{
  width: 75%;
  padding: 5px;
}
