.edit-profile {
  padding: 20px 30px;
  margin: auto;
  box-shadow: 0px 0px 10px 0px #00000029;
  border-radius: 8px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.profile-picture {
  text-align: center;
}
.profile-picture {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile-picture img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}
.edit-picture-button {
  background-color: #660032;
  color: white;
  /* padding: 5px 10px; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.form-group {
  margin-bottom: 5px;
}

.form-group input, 
.form-group textarea {
  width: 100%;
  padding: 5px 10PX;
  border: 1px solid #660032;
  border-radius: 5px;
  font-size: 12px;
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.update-button, 
.reset-button {
  background-color: #660032;
  color: white;
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
