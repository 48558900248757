

.dropdown-menu.custom-dropdown {
    --bs-dropdown-bg: #78023c !important;
   
    border-radius: 0 0 30px 30px;
    width:79em;
        right:0;
        position: absolute;
        transform: translate3d(0px, 70px, 0px) !important;
        // left: 0.8em !important;
}
.navbar-collapse.dropdown-wrap ul{
    text-align: center !important;
    padding-left: 240px;
}
.navbar .navbar-nav .bg-color li a {
    color: #ffffff !important;
}
.navbar .navbar-nav .bg-color li:hover {
    color: #ffffff !important;
}
.navbar .navbar-nav .bg-color li a:hover {
    color: #660032 !important;
}

.custom-dropdown-width {
    width: 1300px; /* Adjust the width as needed */
  }

  .header-item.header-item-inner:hover {
    color: #ffffff;
}
.header-item:hover {
    color: #ffffff !important;
}

.menu-header .navbar .nav-link {
    color: #ffffff; /* Default color for links */
    transition: color 0.3s, background-color 0.3s;
  }
  
 .menu-header .navbar .nav-link:hover,
 .menu-header .navbar .nav-link:focus {
    color: #f79300;
  }
  
 .menu-header .navbar .nav-link:active {
    color: #f79300;
    background-color: rgba(247, 147, 0, 0.2); /* Optional: add a background color on active */
  }
  
 .menu-header .navbar .dropdown-toggle::after {
    display: none; /* Hide default dropdown arrow if needed */
  }
  
 .menu-header .navbar .dropdown-menu {
    background-color: #fff;
  }
  
 .menu-header .navbar .dropdown-item {
    color: #ffffff;
    transition: color 0.3s, background-color 0.3s;
  }
  
 .menu-header .navbar .dropdown-item:hover,
 .menu-header .navbar .dropdown-item:focus {
    color: #f79300;
  }
  
 .menu-header .navbar .dropdown-item:active {
    color: #f79300;
    background-color: rgba(247, 147, 0, 0.2);
  }
  
  .menu-list-center {
    display: flex; /* Use flexbox layout */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
  }
  
  .navbar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .dropdown-menu-end{
    padding: 0px !important;
    margin-right: 0 !important;
  }