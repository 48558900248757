  .custom-grid > .MuiGrid-item {
    padding: 0 5px; 
    display: flex;
    align-items: stretch;
  }

.profile-info {
  padding: 10px;
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  text-align: center;
  overflow: hidden; /* Ensure content doesn't overflow */
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden; /* Ensure content doesn't overflow */
}

.profile-header {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.profile-edit-button {
  background-color: #660032 !important;
  color: white !important;
  align-items: center !important;
  padding: 0px 10px !important;
}
.profile-picture {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.profile-picture-img {
  width: 100px; /* Adjust size as needed */
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}
.profile-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}
.profile-content .profile-name {
  font-weight: bold;
  font-size: 14px;
  color: #660032;
}

.profile-content .profile-role {
  font-size: 12px;
  color: #660032;
}

.profile-details{
  padding: 20px 10px;
}

.profile-details .profile-info-label {
  font-weight: bold;
  color: #660032;
  display: flex;
  text-align: left;
  font-size: 12px !important;
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for overflow text */
}

.profile-details .profile-info-value{
  color: #660032;
  font-size: 12px !important;
  flex: 1;
  text-align: left;
  white-space: nowrap; /* Prevents text wrapping */
  overflow: hidden; /* Hides overflowing text */
  text-overflow: ellipsis; /* Adds ellipsis for overflow text */
  display: block; /* Ensures block display */
  width: 100%; /* Ensures the width fits within the parent container */
  max-width: 100%; /* Ensures the max-width fits within the parent container */
}

.profile-url-icons-container {
  margin-top: auto !important;
  border-top: 1px solid #660032;
  padding: 10px 5px;
}

.profile-url-icons {
  color: #660032;
  margin: 0px 10px;
}







  // .img-width img{
  //   width: 100%;
  // }
  .profile-buttons Button.btn-color{
    background-color: #660032;
    color: #ffffff;
    border-radius: 5px;
    font-size: 10px;
  }
  .profile-buttons Button.btn-border{
    border: 1px solid #660032;
    color: #660032;
    border-radius: 5px;
    background-color: transparent;
    font-size: 10px;
  }
  .info-edit{
    display: block;
    text-align: left;

  }
  .info-edit h6{
    color: #a39999;
  }
  .info-edit h6{
    color: #5a5353;
  }
  .profile-icons {
    display: flex;
    justify-content: center;
  }
  
  .profile-icon {
    color: #660032;
    margin: 0 8px; 
    font-size: 20px !important; 
  }

  input:focus {
    border-color: #660032; 
    outline-color: #660032; 
  }
  .custom-tabs .MuiTab-root {
    color: #660032; 
  }
  .custom-tabs .Mui-selected {
    color: #660032; 
  }



//   .custom-table-cell {
//     text-align: left !important;
//   }
  .table-wrapper-text{
    font-size: 14px !important;
    color: #660032;
  }

  .filter-container {
    display: flex;
    align-items: center;
    // margin-top: 16px;
  }
  
  .filter-button {
    background-color: #660033 !important;
    color: white;
    margin-right: 16px !important;
    font-size: 10px !important;
  }
  
  .filter-options {
    display: flex;
    gap: 10px;
  }
  .text-field .MuiInputBase-input {
    font-size: 10px !important; /* Adjust the font size as needed */
  }
  
  .text-field .MuiInputLabel-root {
    font-size: 10px !important; /* Adjust the label font size as needed */
  }

  .custom-dropdown{
    border-radius: 0;
    border: none;
    padding: 0 !important;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 9.5px 28px !important;
  }
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 5px;
    min-height: -32.5625em;}

  
  .section-two{
    width: 100%;
  }

  .custom-button{
    padding: 3px 15px !important;
  }

