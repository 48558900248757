.account-page {
    padding-bottom: 16px;
  }
  
  .account-page .header {
    margin-bottom: 10px;
  }
  
  .account-page .tabs {
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 24px;
  }
  .tabs .MuiButton-root {
    border: 1px solid #660032;
    color: #660032;
    margin-right: 10px;
  }
  
  .tabs .MuiButton-root.active {
    background-color: #660032;
    color: #ffffff;
    border: none;
  }
  .account-page .tabs button {
    font-weight: bold;
    margin-right: 10px;
  }
  
  .account-page .tabs button.active {
    border-bottom: 2px solid #660032;
  }
  
  .account-page .card {
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  .account-page .card .title {
    font-weight: bold;
    margin-bottom: 16px;
  }
  
  .account-page .card .subtitle {
    font-size: 14px;
    color: #757575;
    margin-bottom: 10px;
  }
  
  .account-page .card .button {
    margin-top: 5px;
  }
  
  .account-page .billing-info {
    margin-top: 16px;
  }
  
  .account-page .billing-info .info-item {
    margin-bottom: 8px;
  }
  
  .account-page .actions {
    display: flex;
    margin-top: 16px;
  }
  
  .account-page .actions .button {
    margin-right: 16px;
  }
  
  .account-page .team-upgrade {
    margin-top: 16px;
  }
  
  .divider {
    border: 1px solid #e0e0e0;
    margin: 20px 0;
  }
  
  .account-page .tabs button.active {
    border-bottom: 2px solid #660032;
  }
  
  .account-page .tabs button:hover {
    text-decoration: none;
    background-color: #660032;
    color: #ffffff;
  }
  
  .update-payment-method-page {
    padding: 24px;
  }
  
  .header {
    margin-bottom: 24px;
  }
  
  .back-button {
    margin-bottom: 16px;
    text-transform: none;
  }
  
  .subheader {
    margin-bottom: 24px;
  }
  
  .alert {
    margin-bottom: 24px;
  }
  
  .payment-form {
    padding: 24px;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  }
  
  .card-icons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 24px;
  }
  
  .card-icon {
    height: 30px;
  }
  
  .lock-icon {
    height: 20px;
  }
  
  .note {
    margin-top: 16px;
    color: #757575;
  }
  
  .form-actions {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
  
  .form-actions .cancel-button {
    margin-left: 16px;
  }
  
  .payment-popup-header {
    font-size: 16px;
    font-weight: 600;
    color: #660032;
    text-transform: uppercase;
  }
  
  .subheader {
    font-size: 15px;
    margin-bottom: 15px;
    font-weight: 600;
  }
.card.upgrade-ac{
  padding: 20px;
  text-align: center;
  height:335px ;
}
.upgrade-ac h4{
  text-transform: uppercase;
  font-size: bold;
}
.upgrade-ac ul li{
  text-decoration: none;
  text-align: left;
  font-size: 14px;
}
.upgrade-ac-btn{
  background-color: #660032 !important;
  color: #ffffff;
  margin-bottom: 20px;
}
.ac-wrap{
  font-size: 12px;
}
.hcolor{
  color: #660032;
}
.payment-info img{
  width: 60px;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
}
[dir] .col-form-label {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  margin-bottom: 0;
}
.payment-info-img{
  border-bottom: 1px solid #757575;
  padding-bottom: 10px;
}
.upgrade-page Step{
  color: #660032 !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #660032 !important;
}
// .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
//   max-width: 60% !important;
// }


.Appinfo{
  margin: 30px 0;
  justify-content: center;
  align-items: center;
}

.payment-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: rgba(52, 52, 52, 0.16) 0px 3px 6px, rgba(56, 56, 56, 0.23) 0px 3px 6px;
}

.payment-box p {
  margin: 0;
  margin-right: 10px;
}

.amount {
  color: #ff0000;
}

.invoice-link {
  color: #007bff;
  text-decoration: none;
}

.invoice-link:hover {
  text-decoration: underline;
}

.pay-now-btn {
  background-color: #28a745;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
  margin-right: 10px;
}

.pay-now-btn:hover {
  background-color: #218838;
}
.table{
  margin: 20px 0;
  padding: 10px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}