.content {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
}

.profile-and-chart {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: flex-start; /* Align items to the start to prevent stretching */
}

.profile-synopsis {
  flex: 1;
  background-color: #fff;
  color: black;
  padding: 10px 10px;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visual separation */
}

.skills-chart {
  flex: 1;
  background-color: #fff;
  color: black;
  padding: 10px 10px;
  border-radius: 8px;
  max-width: 500px; /* Ensure chart doesn't stretch too wide */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visual separation */
}

.recommended-qa {
  background-color: #fff;
  /* color: black; */
  padding: 10px 10px;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visual separation */

}

/* .profile-synopsis .pdf-heading {
  font-size: 12px; 
  
}

.profile-synopsis .content-text {
  font-size: 10px; 
} */

/* .recommended-qa .pdf-question {
  font-size: 12px;
  color: black !important;
} */

/* .recommended-qa .pdf-answer {
  font-size: 10px;
  
} */
.rating {
  color: #660032;
}