.form-wrapper {
  /* padding: 60px; */
  justify-content: center;
}
.custom-button {
  color: white !important;
  background-color: #660032 !important;
  border: none !important;
  cursor: pointer !important;
  padding: 10px 20px !important;
  border-radius: 4px !important;
}

.custom-button:hover {
  background-color: #550028 !important; /* Darken the color slightly on hover */
}
.custom-form-group {
  margin-bottom: 20px;
}

.custom-col {
  padding-right: 5px;
  padding-left: 5px;
}

.custom-input {
  padding: 10px;
}
.custom-contact {
  padding-right: 20px;
}

.custom-form-group {
  margin-bottom: 20px; /* Provides spacing between form groups */
}

.custom-col {
  padding-right: 5px; /* Reduces right padding for tighter spacing */
  padding-left: 5px; /* Reduces left padding for tighter spacing */
}

.full-width-input {
  width: 100%; /* Ensures input takes full width of its container */
}
.mr-2 {
  margin-right: 0.5rem;
}
.custom-button {
  background-color: #660032;
  border-color: #660032;
  color: white;
}

.custom-button:hover {
  background-color: #550026;
  border-color: #550026;
}
/* Add this CSS rule in your vendor.css or appropriate CSS file */
.required-asterisk {
  color: red;
  margin-left: 3px;
}
.password-input-container {
  position: relative;
}

.password-input-container .form-control {
  padding-right: 40px; /* Adjust this value based on the width of the eye icon */
}

.password-input-container .eye-icon {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust this value to align the icon properly */
  transform: translateY(-50%);
  cursor: pointer;
}

.password-requirements {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.toast-progress-bar {
  background-color: #660032 !important; /* Change the progress bar color */
  color: #660032;
}

.custom-toast-icon {
  background-color: #660032; /* Set the background color of the custom icon */
  border-radius: 50%; /* Make the background circular */
  /* display: flex; */
  align-items: center;
  justify-content: center;
  /* width: 24px; 
  height: 24px; */
  color: white; /* Set icon color to white for visibility */
}
.MuiStepIcon-root.Mui-active{
    color: #660032 !important;
}
.MuiStepIcon-root{
  color: #660032 !important;
}