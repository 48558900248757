.ClientManagement__table {
  width: 100% !important;
  table-layout: auto;
}

 .ClientManagement__table th,
.ClientManagement__table td {
  text-align: left;
  white-space: nowrap;
  /* padding: 0 !important; */
  /* font-size: 14px; */
} 

.ClientManagement__table th {
  background-color: white;
  color: #660032;
}


.modal-body {
  padding: 0px;
}

.card-body {
  padding: 0px;
}


.card {
  margin-bottom: 1.5rem;
  box-shadow: none !important;
}

.custom-modal {
  height: fit-content;
  overflow-y: auto;
}

.modal-content{
  border: transparent !important;
}

.modal-close-button {
  color: #ffffff !important;
  background-color: #660032 !important;
  border: none !important;
  right: 0px !important;
  font-size: 25px !important;
  padding: 0px !important;
}

.btn-wrap .btn-style {
  background-color: #660032;
  color: #ffffff;
}

.btn-wrap .btn-style:hover {
  background-color: #660032;
  color: #ffffff;
}

.btn-wrap .btn-border:hover {
  border: 1px solid #660032 !important;
  background-color: transparent;
  color: #660032;
}

.btn-wrap .btn-border {
  border: 1px solid #660032 !important;
  background-color: transparent;
  color: #660032;
}

/* User Management */

.user-management-title-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  margin-bottom: 5px;
}

.user-management-title-icon {
  background-color: #660032 !important;
  margin-left: 10px !important;
  padding: 10px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.user-management-title-icon.inactive{
  opacity: 0.5;
}

.user-management-title-icon-add{
  color: #ffffff;
} 



.client-management-profile-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
}

.client-management-divider {
  border-right: 1px solid #660032;
}

.client-management-img {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 10px;
}
.client-management-name-designation {
  color: #660032;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.client-management-about-user-title {
  padding-left: 10px;
  align-self: flex-start;
}
.client-management-about-user-box {
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  min-height: 50px;
  margin-bottom: 10px;
  background-color: white;
}
.client-management-user-details-box {
  display: flex;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  background-color: white;
}
.client-management-user-details-title {
  padding: 10px;
  flex: 0 0 20%;
}
.client-management-user-details-value {
  padding: 10px;
  flex: 0 0 80%;
}
.client-management-features{
  display: inline-block;
  padding: 4px 8px;
  margin: 4px;
  color: #660032;
  font-weight: bold;
  border-radius: 16px;
  font-size: 12px;
  border: 1px solid #660032;
  align-content: center;
}
.client-management-lock-icon {
  margin-left: 10px;
  vertical-align: middle;
  color: #660032;
  font-size: 12px;
}
.client-management-user-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}
.client-management-user-links-icon {
  color: #660032;
}

.client-management-modal-header {
  margin:0px !important;
  background-color: #660032;
  color: #ffffff !important;
  font-weight: bold;
  padding: 10px 15px !important;
  justify-content: space-between   !important;
}

.client-management-modal-name {
  color: #660032;     
  font-weight: bolder !important;
  font-size: 16px !important;
  text-transform: uppercase;
}

.client-management-modal-labels {
  color: #660032;     
  font-weight: bolder !important;
  font-size: 14px !important;
  text-transform: uppercase;
  padding-bottom: 10px;
  padding-left: 5px;
}

.client-management-modal-text {
  font-weight: bold !important;
  font-size: 12px !important;
}

.client-management-modal-body {
  background-color: #f2f2f2;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.filter-modal{
  display: flex;
  justify-content: center;
  align-items: center;
  border: none !important;
}

.filter-modal-container{
  background-color: white;
}

.filter-modal-title{
  display: flex;
  justify-content: space-between;
  align-content: center;
  font-size: 16px;
  color: white;
  padding: 10px;
  background-color: #660032;
}

.filter-modal-content {
  padding: 20px;
}

.filter-modal-button{
  background-color: #660032;
  color: white;
  padding: 5px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
}

.filter-modal-input{
  width: 100%;
  padding: 5px;
}

.filter-modal-input-date{
  width: 75%;
  padding: 5px;
}

.filter-modal-select{
  width: 100%;
  padding: 7.5px;
}

