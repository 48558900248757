.edit-dropdown-card {
  position: relative;
  padding: 20px;
  width: 400px;
  height: 500px;
  padding-bottom: 0;
}

.dropdown-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
}

.dropdown-close-btn:hover {
  color: #f00;
}

.dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
}

.feature-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.feature-name {
  flex: 2;
  text-align: left;
}

.feature-limit {
  flex: 1;
  text-align: center;
}

.feature-toggle {
  flex: 1;
  text-align: right;
}

.feature-item.header {
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.toggle_switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
  margin-left: 25px;
  size: auto;
}

.toggle_switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch_slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.switch_slider:before {
  position: absolute;
  content: "Inactive";
  color: white;
  text-align: center;
  line-height: 34px;
  height: 34px;
  width: 50%;
  left: 50%;
  background-color: rgb(255, 128, 0);
  transition: 0.4s;
  border-radius: 34px;
  font-size: 9px;
}

input:checked + .switch_slider:before {
  content: "Active";
  background-color: green;
  left: 0;
  font-size: 9px;
}

input:checked + .switch_slider {
  background-color: #ccc;
}

input:focus + .switch_slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked:focus + .switch_slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch_slider .knob {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: transparent;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  border-radius: 34px;
}
