.logout-page{
    overflow: hidden;
    /* height: 400vh !important; */
    display: flex;
    flex-direction: column;
}

.logout-background {
    height: 58vh;
    justify-content: center;
    align-items: center;
    background-image: url('./../../assets/images/t-1.jpg');
    background-size: cover;
    background-position: center;
}
.logout-box{
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 40px;
    margin: auto;
}

.logout-container {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
    padding: 10px;
    max-width: 600px; 
    width: 90%; /* Width that allows it to shrink on smaller screens */
    overflow: hidden; /* Prevents content from overflowing the container */
}