.profile-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 100%;
    margin: 20px 0;
  }
  .profile-card-item span{
    font-size: 12.5px;
  }
  .profile-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .profile-card h3 {
    margin-bottom: 20px;
    font-size: 1.5em;
    color: #333;
  }
  
  .profile-card-items {
    border-top: 1px solid #ddd;
  }
  
  .profile-card-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #ddd;
    font-size: 1.1em;
    color: #555;
  }
  
  .profile-card-item:last-child {
    border-bottom: none;
  }
  
  .arrow {
    color: #aaa;
  }
  .demographic-info-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
  }
  
  .personal-info-box {
    padding: 15px;
    margin-top: 20px;
  }
  .personal-info-button{
    background-color: #660032 !important;
    color: #fff !important;
  }
  .personal-info-box h4{
    font-size: 15px;
    font-weight: 600;
  }
  .setting-form-heading h3{
    font-size: 18px;
    font-weight: 600;
  }
  .language-selection-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
  }
  .tabs .MuiButton-root {
    // border: 1px solid #660032 !important;
    color: #660032;
    margin-right: 10px;
  }
  
  .tabs .MuiButton-root.active {
    background-color: #660032;
    color: #ffffff;
    border: none;
  }
  .setting-box{
    margin-top: 10px;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .manage-email{
    display: flex;
    justify-content: space-between;
  }
  .common-btn{
    background-color: #660032 !important;
    color: #ffffff !important;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    text-transform: capitalize;
  }
  .common-btn:hover{
    background-color: #660032;
    color: #ffffff;
  }
  .border-btn{
    border: 1px solid #660032 !important;
    border-radius: 5px;
    color: #660032 !important;
    padding: 8px 20px;
    background: transparent;
    margin: 0 5px !important;
  }
  .sign-wrap{
    border-bottom: 1px solid #660032;
    margin-bottom: 30px;
  }
  .sign-wrap-inner h6{
    font-size: 10px;
    color: #999;
  }
  .text-size{
    font-size: 8px;
  }
  .btn-color-style{
    background-color: #660032 !important;
    color: #ffffff !important;
  }
  .btn-setup{
    background-color: #660032 !important;
    color: #ffffff !important;
  }
  .btn-setup:hover{
    background-color: #660032;
    color: #ffffff;
  }
