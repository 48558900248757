.view-requirement-info-container {
    background-color: #660032;
    color: white;
    display: flex;
    justify-content: space-between  ;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
}

.view-requirement-info-title {
    text-transform: uppercase;
    font-weight: bold;
}

.view-requirement-info {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    border-left: 4px solid white;
    border-radius: 4px;
    padding-left: 10px;
}

.view-requirement-info-box {
    display: flex;
    align-items: center;
    column-gap: 50px;
    flex-wrap: wrap;
}

.view-requirement-info-box-col {
    display: flex;
    align-items: center;
    gap: 5px;
}

.view-requirement-info-box-col-title {
    font-weight: bold;
}

.view-requirement-info-skills {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
}

.view-requirement-info-skills-chip {
    border-color: white;
    font-size: 12px;
    color: white !important;
}

.view-requirement-job-details-container{
    color: #660032;
    margin-top: 20px;
    margin-bottom: 20px;
}

.view-requirement-job-details-container1 {
    border: 1px solid #660032;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    padding: 30px;
}

.view-requirement-job-details{
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin:10px;
} 
.view-requirement-job-details-label {
    width: 200px;
    font-weight: bold;
    color: #953162;
}
.view-requirement-job-details-value {
    color: #660032;
}
.view-requirement-job-details-description {
    display: flex;
    flex-direction: column;
    margin:10px;
    margin-top: 20px;
}
.view-requirement-job-details-description-box {
    margin-top:10px;
    padding:10px;
    border-radius: 5px;
    border: 1px solid #660032;
    color: #660032;
    min-height: 100px;
}
.view-requirement-job-details-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

