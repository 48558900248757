.gridContainer {
    padding: 0%;
    margin: 0%;
    height: 65vh;
    font-size: 12px;
}

.gridItem {
    padding: 0%;
    margin: 0%;
}

.textColor {
    color: #660032;
}

/* upload box */
.uploadBox {
    background-color: #660032;
    height: 100%;
    padding: 10px;
    overflow: auto;
    border-radius: 10px;
    color: white;
}

.uploadBoxTitle {
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    padding-bottom: 10px;
}

.uploadProfileContainer {
    /* display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 16px 0; */
    /* border: 2px solid #660032;
    border-radius: 8px;
    padding: 32px;
    text-align: center;
    margin-bottom: 16px;
    width: 100%; */
}

.infoField {
    padding: 10px;
}

/* section-2 list view */
.listView {
    border-radius: 5px;
    height: auto;
    overflow: hidden;
    font-size: 12px;
    padding: 0;
}

.listItem {
    background-color: #ffffff;
    padding: 0px;
    border-bottom: 1px solid #660032;
}

.listItem:nth-of-type(odd) {
    background-color: #f9f9f9;
}

.listItem:hover {
    background-color: #e0e0e0;
    cursor: pointer;
}

/* Custom scrollbar styles */
.listView::-webkit-scrollbar {
    display: none;
}

.listView::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

.listView::-webkit-scrollbar-thumb {
    background: #660032;
    border-radius: 10px;
}

.listText {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
    height: 100%;
    align-content: center;
    color: #660032;
}

.imgSize {
    width: 25px;
    height: 25px;
}

.customButtonOne {
    font-size: 12px;
    padding: 4px 5px;
    color: #660032;
    border: #660032 solid 1px;
    border-radius: 5px;
    margin-left: 5px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.customButtonTwo {
    font-size: 12px;
    margin-bottom: 0;
    padding: 4px 5px;
    color: #dc95b7;
    border: #dc95b7 solid 1px;
    border-radius: 5px;
    margin-left: 5px;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.customButtonOne:hover {
    background-color: #660032;
    cursor: pointer;
    color: #ffffff;
    /* Change text color to white on hover */
}

.customButtonTwo:hover {
    border: #dc95b7 solid 1px;
    /* Change text color to white on hover */
}

/* .customButtonTwo {
    padding: 2.5px 4px;
    min-width: auto;
    border-radius: 5px;
    color: #660032;
    border: #660032 solid 1px;
    margin-left: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
} */

/* dropdown */
.dropdownMenu {
    padding: 0;
}

.dropdownMenuItem {
    padding: 0px 4px;
    /* Adjust as needed to make it professional */
}

.dropdownButton {
    margin: 0;
    padding: 0px 4px;
    /* Adjust as needed to make it professional */
}

/* section-3 profile view */
.profileViewBox {
    height: 65vh;
    text-align: center;
    font-size: 12px;
    border: 1px solid #660032;
    overflow: scroll;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    min-height: 52px;
    align-items: baseline;
}