/* SettingsForm.scss */

.settings-form-container {
    padding: 24px;
  }
  
  .form-section-title {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 18px;
    color: #660032;
  }
  
  .text-right {
    text-align: right;
  }

  .btn-color{
    background-color: #660032 !important;
    color: #ffffff;
    border-radius: 5px;
  }