.login-page{
    overflow: hidden;
}

.login-background {
    justify-content: center;
    align-items: center;
    background-image: url('./../../assets/images/t-1.jpg');
    background-size: cover;
    background-position: center;
}

.login-box{
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-top: 20px; */
    padding-bottom: 40px;
    margin: auto;
}

.login-container {
    background-color: #ffffff;
    justify-content: center;
    align-items: center;
    border-radius: 28px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.16);
    padding: 20px;
    max-width: 800px; /* Maximum width of the container */
    width: 90%; /* Width that allows it to shrink on smaller screens */
    overflow: hidden; /* Prevents content from overflowing the container */
}
  
.login-container .login-card {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16); /* Add a subtle shadow */
    border-radius: 24px; /* Optional: Add rounded corners */
  }

.login-form-group {
    margin-bottom: 10px; /* Adds space between form groups */
    position: relative;
    flex-direction: column; /* Stack label and input vertically */
}

.login-image{
    width: 300px;
}

.login-p{
    padding: 5px;
    font-size: 12px;
    font-family: 'Roboto';
}

.register-login{
color: #660032;
font-size: 12px;
font-family: 'Roboto';
}
.login-input-group {
  display: flex;
  align-items: center;
  /* margin: 5px; */
}

.login-input{
    width: 100%;
    border: 0;
    box-sizing: border-box;
    border-radius: 9999px;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
    color: #6c6c6c;
    font-size: 14px;
    font-family: "Roboto";
    /* line-height: 44px; */
    outline: none;  
    /* border-bottom: 1px solid #ab3a71; */
    /* padding-right: '2.5rem'; */
    position: relative; /* Ensure the input field's relative position */
}

.login-password-group {
    display: flex;
    flex-direction: column;
    /* border-bottom: 1px solid #ab3a71; */
  }
  
  .login-input-icon-group {
    position: relative;
    display: flex;
    align-items: center;
  }
 
  .login-password-input {
    flex-grow: 1;
    width: 100%;
    border: 0;
    box-sizing: border-box;
    border-radius: 9999px;
    box-shadow: 0px 2px 8px rgba(0,0,0,0.16);
    color: #6c6c6c;
    font-size: 14px;
    font-family: "Roboto";
    /* line-height: 44px; */
    outline: none;  
    padding-right: 40px;
    padding: 10px; /* Adjust padding for better spacing */
     /* Ensure the input field's relative position */
}

.login-eye-icon{
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 18px; /* Adjust icon size as needed */
    color: #660032;
}

.login-password-requirements {
    color: #f56c6c;
    font-size: 12px;
    margin-top: 5px;
    font-size: 12px;
    font-family: 'Roboto';
  }

.or-separator-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.or-separator-text {
    margin: 0 5px;
    font-weight: bold;
}

.or-separator-line {
    flex-grow: 1;
    height: 1px;
    background-color: #ccc;
}

.footer-social-icon {
    display: flex;
    list-style: none;
    font-weight: bold;
  }
.footer-social-icon a {
    color: #660032;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    transition: background-color 0.3s, border-color 0.3s;
  }

  .toast-progress-bar {
    background-color: #660032 !important; /* Change the progress bar color */
    color: #660032;
  }

  .custom-toast-icon {
    background-color: #660032; /* Set the background color of the custom icon */
    border-radius: 50%; /* Make the background circular */
    /* display: flex; */
    align-items: center;
    justify-content: center;
    /* width: 24px; 
    height: 24px; */
    color: white; /* Set icon color to white for visibility */
  }