.invoice-card-wrap .card {
  width: 230px;
  background-color: white;
  transition: background-color 0.3s, color 0.3s;
}

.invoice-card-wrap .card:hover {
  background-color: #22cabf; 
  color: white; 
}

.invoice-card-wrap .card:hover .MuiTypography-root {
  color: white; 
}

.invoice-card-wrap .card:hover .MuiBox-root {
  color: white; 
}
.icon.icon-1{
  font-size: 25px;
  position: absolute;
  top: 50px;
  right: 30px;
  color: #049d8f;
  height: 25px !important;
  width: 25px !important;
}
.amount{
  font-size: 20px !important;
  font-weight: 600 !important;
}
.badge-paid {
  background-color: #EDE7F6; 
  color: #8E24AA; 
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
}

.badge-unpaid {
  background-color: #FFEBEE; 
  color: #D32F2F; 
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  font-weight: bold;
}

.dropdown-menu {
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 5px;
  border-radius: 4px;
  padding: 5px 0;
  display: flex;
  flex-direction: column;
}

.dropdown-menu button {
  background-color: white;
  color: black;
  padding: 10px;
  border: none;
  text-align: left;
  cursor: pointer;
  width: 100%;
}

.dropdown-menu button:hover {
  background-color: #ddd;
}

