/* Base styles for header navigation bar */
.header-section {
  background-color: transparent;
  padding:0 10px;
  position: relative;
  z-index: 1000;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main__logo {
  display: flex;
  align-items: center;
  margin-left: 0;
}

.header-logo-image{
  /* width: 100px; */
  height: 70px;
  margin-top: 10px;
}

.menu-container {
  display: flex;
  align-items: center;
}

.main-menu {
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 1;
  font-size: 18px;
  font-family: 'Roboto';
}

.main-menu li {
  position: relative;
  margin: 0 1rem;
  font-size: 18px;
  font-family: 'Roboto';
}

.main-menu li a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Roboto';
  text-transform: uppercase;
  transition: color 0.3s ease;
}

.main-menu li a:hover {
  color: #ffce00;
}

.main-menu li:hover .sub-menu {
  display: block;
}

.sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #5a002d;
  list-style: none;
  padding: 0.5rem 0;
  margin: 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  font-size: 12px;
  font-family: 'Roboto';
}

.sub-menu_active {
  display: block;
}

.sub-menu li {
  margin: 0;
}

.sub-menu li a {
  color: #ffffff;
  padding: 0.5rem 1rem;
  display: block;
  text-decoration: none;
  white-space: nowrap;
}

.sub-menu li a:hover {
  background-color: #ffce00;
  color: #5a002d;
}

.menu__components {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.menu__components .dropdown {
  position: relative;
}

.menu__components .dropdown button {
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.menu__components .dropdown-menu {
  background-color: #5a002d;
  border: none;
  box-shadow: none;
}

.menu__components .dropdown-menu a {
  color: #ffffff;
}

.menu__components .dropdown-menu a:hover {
  background-color: #ffce00;
  color: #5a002d;
}

.header-drawer-toggle {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
}

.header-drawer-toggle .icon {
  font-size: 30px;
  margin-left: 20px;
}